/**
 * Parses the connection string and returns an object.
 *
 * Connection strings have the following syntax:
 *
 * ConnectionString ::= Part { ";" Part } [ ";" ] [ WhiteSpace ]
 * Part             ::= [ PartLiteral [ "=" PartLiteral ] ]
 * PartLiteral      ::= [ WhiteSpace ] Literal [ WhiteSpace ]
 * Literal          ::= ? any sequence of characters except ; or = or WhiteSpace ?
 * WhiteSpace       ::= ? all whitespace characters including \r and \n ?
 *
 * @param {string} connectionString The connection string to be parsed.
 * @returns {object} ParsedOutput.
 */
export const parseConnectionString = (connectionString) => {
    const output = {};
    const parts = connectionString.trim().split(";");
  
    for (let part of parts) {
      part = part.trim();
  
      if (part === "") {
        // parts can be empty
        continue;
      }
  
      const splitIndex = part.indexOf("=");
      if (splitIndex === -1) {
        throw new Error(
          "Connection string malformed: each part of the connection string must have an `=` assignment."
        );
      }
  
      const key = part.substring(0, splitIndex).trim();
      if (key === "") {
        throw new Error("Connection string malformed: missing key for assignment");
      }
  
      const value = part.substring(splitIndex + 1).trim();
  
      output[key] = value;
    }
  
    return output;
  }