import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Tooltip, TextField, FormControlLabel, Switch, Snackbar, CircularProgress, LinearProgress } from '@material-ui/core/';
import MuiAlert from '@material-ui/lab/Alert';
import { Delete, Visibility } from '@material-ui/icons';
import { createServiceBusClient, createMessageReceiverAsync } from "../modules/createServiceBusClient"
import { createEvent, createMessageEvent, createErrorEvent } from "../modules/logManager";

const maxNumberOfMessages = 500;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  form: {
  },
  formItem: {
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  inlineInput: {
    marginRight: theme.spacing(2),
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


  let refreshInterval = null;

  export default function ReceiveMessages(props) {
    const {setErrorMessage } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [continuousMode, setContinuousMode] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [fromDLQ, setFromDLQ] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [numberOfMessages, setNumberOfMessages] = useState(1);
    const classes = useStyles();
    const theme = useTheme();
    const { receiveMode = "peekLock" } = props;
    const [receiveResult, setReceiveResult] = useState("Received messages successfully");
    const [openNotification, setOpenNotification] = useState(false);
    const continuousModeBtnLabelPrefix = continuousMode ? subscription !== null ? "Stop " : "Start " : "";
    const continuousModeRefreshInterval = 5000; // refresh every 5 seconds

    useEffect(() => {
      if(props.selectedEntity && !props.selectedEntity.requiresSession){
        setSessionId(null);
      }
    }
    , [ props.selectedEntity ]);


    const handleNotificationClose = (event, reason) => {
      setOpenNotification(false);
    };

    // a function that refreshes the current selected entity if it supports refreshing i.e. to update count of active messages upon receiving a message
    const refresh = () => {
      if(props.selectedEntity && props.selectedEntity.refresh)
      {
        props.selectedEntity.refresh();
      }
    }
    
    const event = Object.assign({}, 
      createEvent(props.entityInfo, receiveMode), 
      fromDLQ ? {
        entityName: `${props.entityInfo.entityName}/$deadLetterQueue`
        }: {}
    );

    const getMessages = async () => {
      // if there is an active subscription this will just clean up / stop and return
      if(receiveMode === "receiveAndDelete" && subscription && subscription.close)
      {
        await subscription.close();
        await subscription.receiver.close();
        await subscription.serviceBusClient.close();
        setIsLoading(false);
        setSubscription(null);
        if(refreshInterval !== null)
        {
          clearInterval(refreshInterval);
          refreshInterval = null;
        }
        setReceiveResult("Stopped receiving messages")
        setOpenNotification(true);
        return;
      }
      

      // start loading indicator
      setIsLoading(true);

      // callback to run when message is received by a message receiver
      // if receiveMode is peekLock, the handler also abandon the message
      const onMessageReceived = (receiver) => async (message) => {
        if(receiveMode === "peekLock") { 
          try {
              await receiver.abandonMessage(message);
          } catch (error) {
            props.onMessageEventReceived(createErrorEvent(Object.assign({}, event, {operation: "abandon"}), props.entityInfo, error, { message }, props.selectedEntity));
          }
        } 
        props.onMessageEventReceived(createMessageEvent(event, message));
      };

      // callback to run when any error occurs while any receive operation is going
      const onReceiveError = (error) => {
        setErrorMessage("Error receiving messages, check logs for details.");
        props.onMessageEventReceived(createErrorEvent(event, props.entityInfo, error, { fromDLQ, sessionId }, props.selectedEntity));
      };

      // create client
      const serviceBusClient = createServiceBusClient(props.entityInfo, props.credentials);
      const receiverOptions = Object.assign({
        receiveMode,
      },
      sessionId ? {
        sessionId,
      }: {},
      fromDLQ  ? {
        subQueueType: "deadLetter"
      } : {});

      try {
        // create receiver
        const receiver = await createMessageReceiverAsync(serviceBusClient, props.entityInfo, receiverOptions, sessionId);
        // start receiving using through continuous mode, then exit to wait for a stop receiving signal
        if(continuousMode)
        {
          setSubscription(Object.assign({}, {
            serviceBusClient,
            receiver
          }, receiver.subscribe({ 
            processMessage: onMessageReceived(receiver), 
            processError: onReceiveError, 
          }, {
            maxConcurrentCalls: 5
          })));
          if(refreshInterval != null)
          {
            clearInterval(refreshInterval);
            refreshInterval = null;
          }
          refreshInterval = setInterval(refresh, continuousModeRefreshInterval);
          setReceiveResult("Started receiving messages")
          setOpenNotification(true);
          return;
        } 
        // receive specific number of messages
        const receivedMessages = await receiver.receiveMessages(numberOfMessages < 1 ? 1 : numberOfMessages, {
          maxWaitTimeInMs: 4000,
        });
        // no messages
        if(receivedMessages.length === 0)
        {
          setReceiveResult("No messages available")
          setOpenNotification(true);
          props.onMessageEventReceived(Object.assign({}, event, {
            time: new Date(),
          }));
        } 
        else {
          // received messages
          setReceiveResult(`Successfully received ${receivedMessages.length} messages.`)
          setOpenNotification(true);
          // process received messages
          await Promise.all(receivedMessages.map(onMessageReceived(receiver)));
        }
        // trigger refresh for UI to reflect number of active messages after receive
        refresh();
        // cleanup after receive specific number of messages
        setIsLoading(false);
        await receiver.close();
        await serviceBusClient.close();
      }
      catch (error) {
        onReceiveError(error);
        setIsLoading(false);
        await serviceBusClient.close();
      }
  }
    return (
      <div className={classes.root}>
          <Snackbar open={openNotification} autoHideDuration={16000} onClose={handleNotificationClose}>
            <Alert onClose={handleNotificationClose} severity="success">
              {receiveResult}
            </Alert>
          </Snackbar>
          <form className={classes.form} noValidate autoComplete="off">
            { continuousMode ? null :
              <div className={classes.formItem}>
              <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  disabled={!!subscription}
                  label="Number of messages to retrieve"
                  value={numberOfMessages}
                  onChange={(e) => {setNumberOfMessages(Number(e.target.value) > maxNumberOfMessages ? maxNumberOfMessages : (Number(e.target.value) < 0 ? 1 : Number(e.target.value)))}}
                  InputProps={{ inputProps: { min: 1, max: maxNumberOfMessages } }}
                  type="number"
                  fullWidth
                />
              </div>
              }
             { (props.selectedEntity && !props.selectedEntity.requiresSession) || fromDLQ ? null :
              <div className={classes.formItem}>
              <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  label="Session Id"
                  value={sessionId}
                  onChange={(e) => {setSessionId(e.target.value.length > 0 ? e.target.value : null)}}
                  fullWidth
                />
              </div>
              } 
            <div className={classes.formItem}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={fromDLQ}
                      onChange={(e) => {setFromDLQ(e.target.checked )}}
                      name="fromDLQ"
                      disabled={!!subscription}
                      color="primary"
                    />
                  }
                  label="From Dead Letter Queue"
                />
              </div>
              {
                receiveMode !== "peekLock" ?
                <div className={classes.formItem}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={continuousMode}
                        onChange={(e) => {setContinuousMode(e.target.checked )}}
                        name="continuousMode"
                        disabled={!!subscription}
                        color="primary"
                      />
                    }
                    label="Continuous (Purge)"
                  />
                </div>
                : null
              }
              <div className={classes.formItem}>
              <Tooltip title={props.entityInfo.isValid ? "" : "Please make sure you fill entity information details correctly."} placement="right">
                <div style={{display: "inline-block", padding: "0px", margin: "0px"}}>
                <Button onClick={getMessages} disabled={(isLoading || (!props.entityInfo.isValid)) && !subscription} variant="contained" color="primary" className={classes.button} endIcon={receiveMode === "peekLock" ? <Visibility /> : <Delete />}>
                    {receiveMode === "peekLock" ? `${continuousModeBtnLabelPrefix}Peek` : `${continuousModeBtnLabelPrefix}Receive and Delete`}
                  </Button>
                </div>
              </Tooltip>
              </div>
              {isLoading 
              ? <div style={{marginLeft: theme.spacing(0.5), marginTop: theme.spacing(2)}} className={classes.formItem}>
                  {
                    continuousMode ?
                      <LinearProgress />
                    : <CircularProgress color="secondary" />
                  }
              </div>
              : undefined  
              }
          </form>
      </div>
    );
  }

  export { maxNumberOfMessages, ReceiveMessages };