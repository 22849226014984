const axios = require('axios').default;

const cachedTokenResponses = {};

export default class ClientSecretProxyCredentials {
  tenantId; clientId; clientSecret; proxyApiTokenCredentials;
  constructor(tenantId, clientId, clientSecret, proxyApiTokenCredentials) {
    this.tenantId = tenantId;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.proxyApiTokenCredentials = proxyApiTokenCredentials;
  }
  async getToken(scopes) {
    const cachKey = `${this.tenantId}-${this.clientId}-${this.clientSecret}`;
    // if there is no cached token
    // or if there is a cacked token but its expire or will expire in 3 munites
    // we go and fetch a new token via AAD from the API
    if (!cachedTokenResponses[cachKey] || cachedTokenResponses[cachKey].expiresOnTimestamp - 180000 <= (new Date()).getTime()) {
      try {
        const { token } = await this.proxyApiTokenCredentials.getToken(["openid", "https://servicebus.cloudbricks.io/api"]);
        const { data } = await axios.post('/api/client_credentials/token', {
          tenantId: this.tenantId,
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          scopes: typeof scopes === 'string' ? scopes.split(" ") : scopes //some old versions of Azure SDK for js pass scopes as string. new ones as array
        }, {
            headers: {
                //passing x-aswa-authorization is TMEPORARLY until: https://github.com/Azure/static-web-apps/issues/34
                //Authorization: `Bearer ${token}`,
                "x-aswa-authorization": `Bearer ${token}`,
            },
        });
        cachedTokenResponses[cachKey] = data;
      } catch (error) {
        throw (error?.response?.data?.message || error);
      }
    }
    return cachedTokenResponses[cachKey];
  }
}