const axios = require('axios').default;

export const hasLicence = async (tenantId, token) => {
    const { data: subscriptions = [] } = await axios.get(`/api/subscriptions/bytenant/${encodeURIComponent(tenantId)}`, {
        headers: {
            //passing x-aswa-authorization is TMEPORARLY until: https://github.com/Azure/static-web-apps/issues/34
            //Authorization: `Bearer ${token}`,
            "x-aswa-authorization": `Bearer ${token}`,
        }
    });
    return subscriptions.filter((sub) => sub.saasSubscriptionStatus === "Subscribed").length > 0;
};