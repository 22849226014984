import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Refresh } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        overflow: "hidden"
    },
    formItem: {
        marginBottom: theme.spacing(2),
    },
    radioContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    chips: {
      marginTop: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    entityInfoLabel: {
      wordBreak: 'break-word'
    }
  }));


  export default function EntityInfo(props) {
    const classes = useStyles();
    const selectedEntity =  props.selectedEntity ? { 
      ...props.selectedEntity,
      ...props.selectedEntityAdditionalProperties
    } : props.selectedEntity;
    const onFieldChange = (name, value) => {
      props.entityInfo[name] = value;
      props.onEntityInfoChanged(props.entityInfo);
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <Typography variant="h6">
                Entity Information
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {selectedEntity && selectedEntity.refresh 
            ? <Tooltip title="Reload">
                <IconButton onClick={() => { selectedEntity.refresh() }}>
                  <Refresh fontSize="small" />
                </IconButton>
              </Tooltip>
            : <></>  
          }
          </Grid>
        </Grid>

        <form className={classes.form} noValidate autoComplete="off">
          
          {selectedEntity ? <Typography className={classes.entityInfoLabel} variant="body1"><strong>Namespace:</strong> {selectedEntity.namespaceUrl}</Typography> : <div className={classes.formItem}><TextField 
            size="small"
            onChange={(e) => { onFieldChange("namespaceUrl", e.target.value) }} 
            variant="outlined"
            value={props.entityInfo.namespaceUrl} 
            fullWidth 
            disabled={selectedEntity}
            label="Namespace URL" /></div>
          }

          
          {selectedEntity ? <Typography className={classes.entityInfoLabel} variant="body1"><strong>Name:</strong> {selectedEntity.type === "subscription" ? `${selectedEntity.topic.name}/${selectedEntity.name}` : selectedEntity.name}</Typography> :
            <div className={classes.formItem}><TextField 
              size="small"
              onChange={(e) => { onFieldChange("entityName", e.target.value) }} 
              variant="outlined"
              value={props.entityInfo.entityName} 
              fullWidth 
              disabled={selectedEntity}
             label="Entity Name" /></div>
            }
          
          
          {selectedEntity ? <Typography className={classes.entityInfoLabel} variant="body1"><strong>Type:</strong> {selectedEntity.type}</Typography> :
          <FormControl className={classes.radioContainer} component="fieldset">
            <FormLabel component="legend">Entity Type</FormLabel>
            <RadioGroup row  aria-label="entityType" name="entityType">
                <FormControlLabel value="queue" onChange={(e) => { onFieldChange("entityType", e.target.value) }} checked={props.entityInfo.entityType === 'queue'} control={<Radio />} label="Queue" />
                <FormControlLabel value="topic" onChange={(e) => { onFieldChange("entityType", e.target.value) }} checked={props.entityInfo.entityType === 'topic'} control={<Radio />} label="Topic" />
                <FormControlLabel value="subscription" onChange={(e) => { onFieldChange("entityType", e.target.value) }} checked={props.entityInfo.entityType === 'subscription'} control={<Radio />} label="Subscription" />
            </RadioGroup>
          </FormControl>}
          
          

          {selectedEntity && selectedEntity.forwardTo ?
              <Typography variant="body1"><strong>Forward to:</strong> {selectedEntity.forwardTo}</Typography>
            : undefined}

          {selectedEntity && selectedEntity.forwardDeadLetteredMessagesTo ?
              <Typography variant="body1"><strong>Forward DLQ to:</strong> {selectedEntity.forwardDeadLetteredMessagesTo}</Typography>
            : undefined}

          {selectedEntity && selectedEntity.rules ?
              selectedEntity.rules.map((rule) => rule.sqlFilter && rule.sqlFilter.sqlExpression ? <Typography variant="body1"><strong>SQL Filter:</strong> {rule.sqlFilter.sqlExpression}</Typography> : <></>)
            : undefined}  
          

          {selectedEntity ? 
        <div className={classes.chips}>
          <Chip
            label={`Active: ${selectedEntity.countDetails.activeMessageCount} messages`}
            color="primary"
          />
          <Chip
            label={`Dead-Lettered: ${selectedEntity.countDetails.deadLetterMessageCount} messages`}
            color="primary"
          />
          <Chip
            label={`Scheduled: ${selectedEntity.countDetails.scheduledMessageCount} messages`}
            color="primary"
          />
        </div>
        : undefined}

        </form>
      </div>
    );
  }