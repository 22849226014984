const axios = require('axios').default;

export const resolveSubscription = async (marketplaceToken, token) => {
    return await axios.post("/api/subscriptions/resolve", {}, {
        params: {
            token: marketplaceToken
        },
        headers: {
            //TODO: passing x-aswa-authorization is TMEPORARLY until: https://github.com/Azure/static-web-apps/issues/34
            //Authorization: `Bearer ${token}`,
            "x-aswa-authorization": `Bearer ${token}`,
        }
    });
};
