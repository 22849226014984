import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
   footer: {
       "width": "100%",
       "text-align": "center",
       "padding-top": "30px",
       "padding-bottom": "20px",
       "display": "block",
       "opacity": 0.5
   },
   footerText: {
    "width": "100%",
    "text-align": "center"
   }
}));

export default function Footer () {
    const { instance } = useMsal();
    const userTenantId = instance.getActiveAccount()?.tenantId || "User not logged in";
    const classes = useStyles();
    const currentYear = new Date().getFullYear();
    const mailto = `mailto:support@cloudbricks.io?subject=Support%20request%20regarding%20Service%20Bus%20Cloud%20Explorer%20[Tenant%20Id%3A%20${encodeURIComponent(userTenantId)}]&body=%0D%0A---------------------------------------------------%0D%0AAdditional%20Technical%20Details%0D%0ATenant%20Id%3A%20${encodeURIComponent(userTenantId)}%0D%0ASession%20Id%3A%20${encodeURIComponent(window.appInsights.context.getSessionId())}%0D%0AURL%3A%20${encodeURIComponent(window.location.href)}%0D%0ALocal Time%3A%20${encodeURIComponent(new Date().toLocaleString())}%0D%0AISO Time%3A%20${encodeURIComponent(new Date().toISOString())}`;
    return <footer className={classes.footer}>
                <Typography className={classes.footerText} variant='body2' color='textPrimary'>© {currentYear} Cloud Bricks | ABN: 46 419 121 674 |&nbsp;
                    <Link href={mailto}>Support</Link> |&nbsp;
                    <Link target="_blank" rel="noopener noreferrer" href="https://github.com/cloudbricksio/ServiceBusCloudExplorerSupport/wiki/User-guide">Documentation</Link> |&nbsp;
                    <Link target="_blank" rel="noopener noreferrer" href="https://github.com/cloudbricksio/ServiceBusCloudExplorerSupport/wiki/Privacy-statement">Privacy Policy</Link>
                </Typography>
           </footer>
}
