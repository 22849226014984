export default class StaticTokenCredentials {
  token;
  constructor(token) {
    this.token = token;
  }
  async getToken() {
    return {
      token: this.token.replace ? this.token.replace("bearer ", "").replace("Bearer ", "") : this.token
    };
  }
}