import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import EntityMessageManager from './EntityMessageManager.js';
import EntityBrowser from './EntityBrowser.js'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MuiAlert from '@material-ui/lab/Alert';
import EntityInfo from './EntityInfo';
import SelectedMessage from './SelectedMessage';
import { LogViewer } from './LogViewer';
import { isEntityInfoValid } from '../modules/isEntityInfoValid';
import AuthenticationInfo from './AuthenticationInfo';
import Footer from '../components/Footer';
import { makeStyles } from '@material-ui/core/styles';
import { hasLicence } from '../modules/subscriptionManagement/hasLicence';
import { useMsal } from "@azure/msal-react";
import jwt_decode from "jwt-decode";

const drawerWidth = "17vw;";
const drawerMinWidth = "230px";
const drawerMaxWidth = "500px";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    minWidth: drawerMinWidth,
    maxWidth: drawerMaxWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    minWidth: drawerMinWidth,
    maxWidth: drawerMaxWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  loginBtn: {
    margin: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  spinner: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(3),
  },
  paper: {
    marginBottom: theme.spacing(2),
  }
}));

export default function App({ setErrorMessage, loggedInAccount }) {
  const classes = useStyles();
  const [isLicenced, setIsLicenced] = useState("unknown");
  const [logs, setLogs] = useState([]);
  const [selectedEntityAdditionalProperties, setSelectedEntityAdditionalProperties] = useState({});
  const [selectedEntity, setSelectedEntity] = useState();
  const [selectedMessage, setSelectedMessage] = useState();
  const [entityInfo, setEntityInfo] = useState({
    namespaceUrl: "",
    entityName: "",
    authenticateVia: "connectionString",
    entityType: "queue",
    connectionString: "",
    sasToken: "",
    sasKey: "",
    sasKeyName: "",
    aadCleintId: "",
    aadCleintSecret: "",
    aadTenantId: "",
    jwtToken: ""
  });

  const { instance } = useMsal();
  const { browserCredentials } = instance;
  const isLoggedIn = !!loggedInAccount;

  useEffect(() => {
    const doAsync = async () => {
      if (loggedInAccount) {
        const token = loggedInAccount.accessToken;
        const jwt = jwt_decode(token);
        setEntityInfo((old) => {
          const entityInfo = {
            ...old,
            authenticateVia: "yourIdentity",
            aadTenantId: jwt["tid"],
          }
          return Object.assign({}, entityInfo, {
            isValid: isEntityInfoValid(entityInfo)
          });
        });
        /////////////////////// Detecting if a user have liecense or not
        try {
          const hasLic = await hasLicence(jwt["tid"], token);
          setIsLicenced(hasLic)
          if (!hasLic) {
            window.appInsights.trackEvent({
              name: "Unlicensed user logged in",
              properties: jwt
            });
          }
        } catch (error) {
          window.appInsights.trackException({
            exception: error,
            properties: {
              process: "Detecting if a user have liecense or not"
            }
          });
        }
      }

    }
    doAsync();
  }, [browserCredentials, loggedInAccount]);


  const onEntityAdditionalInfoProvided = (properties) => {   
    setSelectedEntityAdditionalProperties(old => {
      return {
        ...old,
        ...properties
      }
    })
  }
  
  const onEntitySelected = (entity) => {
    if (entity) {
      const newEntityInfo = {
        ...entityInfo,
        namespaceUrl: entity.namespaceUrl.replace(':443', ''),
        entityName: entity.type === "subscription" 
          ? `${entity.topic.name.replace("~", "/")}/${entity.name}` 
          : entity.name.replace("~", "/"),
        entityType: entity.type,
        isValid: isEntityInfoValid(entityInfo)
      };
      setSelectedEntityAdditionalProperties({}); // clears additional properties in prep for presenting new ones
      setEntityInfo(Object.assign({}, newEntityInfo, {
        isValid: isEntityInfoValid(newEntityInfo)
      }));
      setSelectedEntity(entity);
    }
  }

  const onEntityInfoChanged = (entityInfo) => {
    setEntityInfo({
      ...entityInfo,
      isValid: isEntityInfoValid(entityInfo)
    });
  }

  const onMessageEventReceived = (event) => {
    setLogs((old) => {
      return [...old, event];
    })
  }

  const onLogsCleared = () => {
    setLogs([]);
  }



  return (
    <div className={classes.root}>
      <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }} anchor="left">
        <div className={classes.toolbar} />
        <Divider />
        {isLoggedIn === true ? <>
          <EntityBrowser isLoggedIn={isLoggedIn} isLicenced={isLicenced} credentials={browserCredentials} setErrorMessage={setErrorMessage} onEntitySelected={onEntitySelected} onEntityAdditionalInfoProvided={onEntityAdditionalInfoProvided} selectedEntity={selectedEntity} />
        </> : <></>
        }
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          {isLoggedIn === true && (typeof isLicenced === "boolean" && !isLicenced) ?
            <Grid item xs={12}><MuiAlert severity="warning">You're using a free <a href='https://cloudbricks.io/pages/service-bus-cloud-explorer-pricing/' target='_blank' rel="noreferrer">limited version</a> of this software. For full functionality <a href='https://azuremarketplace.microsoft.com/en-us/marketplace/apps/cloudbricks1626490617482.service_bus_sas_1?tab=Overview' target='_blank' rel="noreferrer">create a subscription</a> for your organization.</MuiAlert></Grid>
            : <></>}
          
          <Grid item xs={8}>
            <Paper className={classes.paper}>
              <EntityMessageManager onMessageSelected={setSelectedMessage} isLoggedIn={isLoggedIn} isLicenced={isLicenced} setErrorMessage={setErrorMessage} credentials={browserCredentials} entityInfo={entityInfo} onMessageEventReceived={onMessageEventReceived} selectedEntity={selectedEntity} />
            </Paper>
          </Grid>

          <Grid item xs={4}>
            
            {selectedMessage ? <Paper className={classes.paper}>
              <SelectedMessage selectedMessage={selectedMessage} />
            </Paper>
            : <></>}
            
            <Paper className={classes.paper}>
              <EntityInfo isLoggedIn={isLoggedIn} selectedEntityAdditionalProperties={selectedEntityAdditionalProperties} isLicenced={isLicenced} credentials={browserCredentials} selectedEntity={selectedEntity} entityInfo={entityInfo} onEntityInfoChanged={onEntityInfoChanged} />
            </Paper>

            <Paper className={classes.paper}>
              <AuthenticationInfo isLoggedIn={isLoggedIn} isLicenced={isLicenced} credentials={browserCredentials} entityInfo={entityInfo} onEntityInfoChanged={onEntityInfoChanged} />
            </Paper>

          </Grid>

          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <Paper className={classes.paper}>
              <LogViewer logs={logs} onLogsCleared={onLogsCleared} />
            </Paper>
          </Grid>
        </Grid>
        <Footer />
      </main>
    </div>
  );
};