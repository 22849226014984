import { parseConnectionString } from '../modules/parseConnectionString';
import jwt_decode from "jwt-decode";

const connectionStringValidator = (value) => {
    try {
      const keys = Object.keys(parseConnectionString(value)).map(k => k.toLocaleLowerCase());
      return  keys.includes("endpoint") &&
              keys.includes("sharedaccesskeyname") &&
              keys.includes("sharedaccesskey");
    } 
    catch {
      return false;
    }
  };

  const sasTokenValidator = (value) => {
    return value 
            && value.includes("SharedAccessSignature ")
            && value.includes("sr=")
            && value.includes("sig=")
            && value.includes("se=")
            && value.includes("skn=")
  };

  const namespaceUrlValidator = (value) => {
    return value && value.match(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi);
  };

  const sasKeyValidator = (key, keyName) => {
    return !!(key && keyName);
  };

  const jwtTokenValidator = (value) => {
    try {
      if(!value) return false;
      const newVal = value.replace ? value.replace("bearer ", "").replace("Bearer ", "") : value;
      const jwt = jwt_decode(newVal);
      return !!jwt;
    } catch {
      return false;
    }

  };


export const isEntityInfoValid = (entityInfo) => {
    const hasUrl = namespaceUrlValidator(entityInfo.namespaceUrl);
    const hasEntityName = entityInfo.entityName && entityInfo.entityName.length > 1;
    switch (entityInfo.authenticateVia) {
    case "connectionString":
        return hasUrl && hasEntityName && connectionStringValidator(entityInfo.connectionString);
    case "sasToken":
        return hasUrl && hasEntityName && sasTokenValidator(entityInfo.sasToken);
    case "sasKey":
        return hasUrl && hasEntityName && sasKeyValidator(entityInfo.sasKey, entityInfo.sasKeyName);
    case "yourIdentity":
        return  hasUrl && hasEntityName;
    case "aadClientIdAndSecret":
      return  hasUrl && hasEntityName && entityInfo.aadTenantId && entityInfo.aadTenantId.length > 0 && entityInfo.aadCleintId && entityInfo.aadCleintId.length > 0 && entityInfo.aadCleintSecret && entityInfo.aadCleintSecret.length > 0;
    case "jwtToken":
      return hasUrl && hasEntityName && jwtTokenValidator(entityInfo.jwtToken);
    default:
        throw Error();
    }
};