import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Typography, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead,
    TablePagination, 
    TableRow, 
    Link, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Button,
    Tooltip,
    Grid,
    IconButton
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Clear, Code } from '@material-ui/icons';

const columns = [
    { id: 'time', label: 'Time'},
    { id: 'namespace', label: 'Namespace' },
    { id: 'entityName', label: 'Entity' },
    { id: 'entityType', label: 'Type'},
    { id: 'operation', label: 'Operation'},
    { id: 'message', label: 'Message'},
    { id: 'error', label: 'Status'},
  ];


  const createData = ({namespace, entityName, operation, message, error, time, entityType, statusMessage}) => {
    return { 
        namespace, 
        entityName, 
        entityType,
        operation, 
        message, 
        error,
        time: time.toLocaleTimeString(),
        statusMessage
    };
  }


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
      },
    container: {
        width: '100%'
    },
    syntaxHighlighter: {
        overflowX: "hidden!important",
        wordBreak: 'break-word'
    }
  }));

  const itemSort = function(a, b) {
    if (a.time < b.time) {
      return 1;
    }
    if (a.time > b.time) {
      return -1;
    }
    return 0;
  }


  export function LogViewer(props) {
    const [selectedMessage, setSelectedMessage] = useState(undefined);
    const selectedMessage_processed = selectedMessage 
        && selectedMessage.body 
        && Buffer.isBuffer(selectedMessage.body) ? {
            ...selectedMessage,
            body: selectedMessage.body.toString('utf8')
        } : selectedMessage;
    const [messageDialogOpened, setMessageDialogOpened] = useState(false);
    const [messageDialogTitle, setMessageDialogTitle] = useState("Selected Message");
    const rows = props.logs.sort(itemSort).map(createData) || [];
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleMessageDialogClose = () => {
        setMessageDialogOpened(!messageDialogOpened);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getCellUi = ({value, id, row}) => {
        switch (id) {
            case "message":
                if(row.operation === "send (import)") return "[NA]";
                if (!value) return "[None]";
                const messageText = value.body && Buffer.isBuffer(value.body) ? value.body.toString('utf8')  : typeof(value.body) === "object" ? JSON.stringify(value.body) : (!value.body ? "[Empty]": (value.body.toString ? value.body.toString() : value.body));
                return <Link
                            component="button"
                            variant="body2"
                            style={{fontWeight: "bold"}}
                            underline="always"
                            onClick={() => {
                                setMessageDialogTitle("Selected Message");
                                setSelectedMessage(value);
                                setMessageDialogOpened(true);
                            }}>
                            {messageText.length > 50 ? messageText.substr(0, 50) + ".." : messageText}
                        </Link>
            case "error":
                return !value 
                    ? <Link
                        component="button"
                        style={{fontWeight: "bold"}}
                        variant="body2"
                        underline="always"
                        onClick={() => {
                            setMessageDialogTitle("Operation Details");
                            setSelectedMessage(row);
                            setMessageDialogOpened(true);
                        }}>
                        {row.statusMessage || "Succeeded"} 
                    </Link>
                    : <Link
                            component="button"
                            color="error"
                            style={{fontWeight: "bold"}}
                            variant="body2"
                            underline="always"
                            onClick={() => {
                                setMessageDialogTitle("Error Details");
                                setSelectedMessage(value);
                                setMessageDialogOpened(true);
                            }}>
                            {value.statusMessage || "Failed"} 
                        </Link>;
            default:
                return value;
        }
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={messageDialogOpened}
                onClose={handleMessageDialogClose}
            >
                <DialogTitle id="max-width-dialog-title">{messageDialogTitle}</DialogTitle>
                <DialogContent>
                    <SyntaxHighlighter language="json" 
                    style={atomOneDark} 
                    wrapLongLines={true}
                    wrapLines={true}
                    className={classes.syntaxHighlighter}>
                        {selectedMessage_processed ? JSON.stringify(selectedMessage_processed, null, 4) : "No Content"}
                    </SyntaxHighlighter>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleMessageDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6">Event log</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        >
                                    {column.label}
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => 
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map((column) => {
                                            return (
                                            <TableCell key={column.id} align={column.align}>
                                                {getCellUi({value: row[column.id], id: column.id, row})}
                                            </TableCell>
                                            );
                                        })}
                                    </TableRow>)}
                            </TableBody>
                            </Table>
                        </TableContainer>
                <Grid container spacing={0}>    
                    <Grid item xs={6}>
                        <Tooltip title="Clear">
                            <IconButton onClick={() => {
                                    props.onLogsCleared();
                                }}>
                                <Clear fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Code View">
                            <IconButton onClick={() => {
                                    setMessageDialogTitle("Sent and received messages");
                                    setSelectedMessage(rows);
                                    setMessageDialogOpened(true);
                                }}>
                                <Code fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100, 1000]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
  }
