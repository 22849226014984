import 'date-fns';
import React, { useState, Fragment } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { createServiceBusClient, createMessageSender } from '../modules/createServiceBusClient';
import { LinearProgress, Grid, Tooltip, TextField, Button, Icon, Snackbar, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress, FormControlLabel, Switch, FormLabel } from '@material-ui/core/';
import { ExpandMore } from '@material-ui/icons';
import { createEvent, createErrorEvent, createMessageEvent } from "../modules/logManager";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'; 
import { Buffer } from "buffer";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  form: {
  },
  formItem: {
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  inlineInput: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  accordionDetails: {
    display: "block"
  },
  advancedPropertyContainer: {
    display: "flex"
  }
}));



export default function SendMessage(props) {
  const { setErrorMessage, isLicenced } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [contentType, setContentType] = useState("application/json");
  const [body, setBody] = useState("");
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [messageId, setMessageId] = useState(undefined);
  const [ignoreMessageId, setIgnoreMessageId] = useState(true);
  const [importMode, setImportMode] = useState(false);
  const [correlationId, setCorrelationId] = useState(undefined);
  const [sessionId, setSessionId] = useState(undefined);
  const [enableSchedualMessage, setEnableSchedualMessage] = useState(false);
  const [enableSetTimeToLive, setEnableSetTimeToLive] = useState(false);
  const [subject, setSubject] = useState(undefined);
  const [replyTo, setReplyTo] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const [partitionKey, setPartitionKey] = useState(undefined);
  const [replyToSessionId, setReplyToSessionId] = useState(undefined);
  const [customProperties, setCustomProperties] = useState([{ "key": "", "value": "" }]);
  const [openNotification, setOpenNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [schedualMessageAt, setSchedualMessageAt] = useState(new Date());
  const [timeToLiveHours, setTimeToLiveHours] = useState(336);
  const [messagesToImport, setMessagesToImport] = useState([]);

  const tryParseJson = (value) => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  }

  const importMessages = async () => {
    setIsLoading(true);
    const event = Object.assign({}, createEvent(props.entityInfo, "send (import)"));
    const serviceBusClient = createServiceBusClient(props.entityInfo, props.credentials, 3);
    const sender = createMessageSender(serviceBusClient, props.entityInfo);
    try {
      const batches = [];
      const notAcceptedMessages = [];
      const createNewBatch = async () => {
        // TODO: check if we need to add maxSizeInBytes: 1048576 because: https://github.com/Azure/azure-sdk-for-net/issues/25381#issuecomment-1062133794
        const messageBatch = await sender.createMessageBatch();
        batches.push(messageBatch);
      }
      await createNewBatch();
      for (const message of messagesToImport) {
        //delete readonly properties
        if(ignoreMessageId) delete message.messageId;
        delete message.sequenceNumber;
        delete message.enqueuedSequenceNumber;
        delete message.enqueuedTimeUtc;
        if(message.body && message.body.type === "Buffer" && message.body.data && Array.isArray(message.body.data))
        {
          message.body = Buffer.from(message.body.data);
        }
        if (!batches[batches.length - 1].tryAddMessage(message)) {
          await createNewBatch();
          if(!batches[batches.length - 1].tryAddMessage(message))
          {
            notAcceptedMessages.push(message);
          }
        }
      }
      const validBatches = batches.filter((b) => b.count > 0);
      if(notAcceptedMessages.length > 0)
      {
        props.onMessageEventReceived(createErrorEvent(event, props.entityInfo, { message: `During create batches, ${notAcceptedMessages.length} messages where not accepted. Most likely due to size constrains on service bus entity. Check console debug for the full list.`  }, { statusMessage: `Failed to import ${notAcceptedMessages.length} message${notAcceptedMessages.length > 1 ? "s" : ""}` }, props.selectedEntity));
        console.debug({ notAcceptedMessages });
      }
      let index = 0;
      for (const batch of validBatches) {
        index++;
        setLoadingProgress((index / validBatches.length) * 100);
        try {
          await sender.sendMessages(batch);
          props.onMessageEventReceived(Object.assign({}, event, {
            time: new Date(),
            statusMessage: `${batch.count} messages imported sucessfully`
          }));
        } catch (error) {
          props.onMessageEventReceived(createErrorEvent(event, props.entityInfo, error, { statusMessage: `Failed to send a batch of ${batch.count} messages` }, props.selectedEntity));
          setErrorMessage("Failed to import some messages, check event log for details");
        }
      }
    } catch (error) {
      props.onMessageEventReceived(createErrorEvent(event, props.entityInfo, error, { statusMessage: `Failed to import messages` }, props.selectedEntity));
      setErrorMessage("Failed to import messages, check event log for details");
    } finally {
      if (props.selectedEntity && props.selectedEntity.refresh) {
        props.selectedEntity.refresh();
      }
      setLoadingProgress(0);
      setIsLoading(false);
      await sender.close();
      await serviceBusClient.close();
    }
  }

  const send = async () => {
    setIsLoading(true);
    const serviceBusClient = createServiceBusClient(props.entityInfo, props.credentials);
    const sender = createMessageSender(serviceBusClient, props.entityInfo);
    const message = Object.assign({
      body: contentType.indexOf("json") > -1 ? tryParseJson(body) : body,
      applicationProperties: customProperties.reduce((properties, { key, value }) => Object.assign({}, properties, key ? { [key]: value } : {}), {})
    },
      correlationId ? { correlationId } : {},
      sessionId ? { sessionId } : {},
      subject ? { subject } : {},
      messageId ? { messageId } : {},
      contentType ? { contentType } : {},
      replyTo ? { replyTo } : {},
      to ? { to } : {},
      partitionKey ? { partitionKey } : {},
      replyToSessionId ? { replyToSessionId } : {},
      enableSchedualMessage && schedualMessageAt ? { scheduledEnqueueTimeUtc: schedualMessageAt } : {},
      enableSetTimeToLive && timeToLiveHours > 0 ? { timeToLive: (1000 * 60 * 60 * timeToLiveHours) } : {}
    );
    const event = Object.assign(createEvent(props.entityInfo, "send"), {
      message
    });

    try {
      await sender.sendMessages(message);
      props.onMessageEventReceived(createMessageEvent(event, message));
      setOpenNotification(true);
    } catch (error) {
      setErrorMessage("Error while sending a message, check logs for details.");
      props.onMessageEventReceived(createErrorEvent(event, props.entityInfo, error, { message, sessionId }, props.selectedEntity));
    }
    if (props.selectedEntity && props.selectedEntity.refresh) {
      props.selectedEntity.refresh();
    }
    await sender.close();
    await serviceBusClient.close();
    setIsLoading(false);
  };

  const handleNotificationClose = (event, reason) => {
    setOpenNotification(false);
  };


  const onCustomPropertyRenamed = (index, key) => {
    setCustomProperties((oldCustomProperties) => {
      const customProperties = [...oldCustomProperties];
      customProperties[index].key = key;
      const filtered = customProperties.filter(({ key }) => key);
      return filtered.concat((filtered[filtered.length - 1] && filtered[filtered.length - 1].key) || filtered.length === 0 ? [{ key: "", value: "" }] : []);
    });
  }

  const onCustomPropertyChanged = (index, value) => {
    setCustomProperties((oldCustomProperties) => {
      const customProperties = [...oldCustomProperties];
      customProperties[index].value = value;
      const filtered = customProperties.filter(({ key }) => key);
      return filtered.concat((filtered[filtered.length - 1] && filtered[filtered.length - 1].key) || filtered.length === 0 ? [{ key: "", value: "" }] : []);
    });
  }

  const onImportFile = async (event) => {
        if (event.target.files.length > 0)
        {
            try {
              setIsLoading(true);
              setMessagesToImport([]);
              for (const file of event.target.files) {
                const contents = await file.text();
                const messages = JSON.parse(contents);
                const normalized = Array.isArray(messages) ? messages : [messages];
                // message validations
                normalized.forEach((message, index) => {
                  if (!message || typeof message !== 'object') {
                    throw Error(`Invalid message definition at item # ${index}`);
                  }
                  // if body is a buffer-like definition, set body as a buffer from data array
                  if (message.body && message.body.type === "Buffer" && message.body.data && Array.isArray(message.body.data)) {
                    message.body = Buffer.from(message.body.data);
                  }
                });
                setMessagesToImport((existing) => {
                  return [...existing, ...normalized]
                });
              }
              setIsLoading(false);
            } catch (error) {
              setIsLoading(false);
              setErrorMessage(error.message);
            }
      }
  }

  return (
    <div className={classes.root}>
      <Snackbar open={openNotification} autoHideDuration={6000} onClose={handleNotificationClose}>
        <Alert onClose={handleNotificationClose} severity="success">
          Message sent successfully
        </Alert>
      </Snackbar>
      <form className={classes.form} noValidate autoComplete="off">

        {!importMode ?
          <>
            { /* Single message UI */ }
            <div className={classes.formItem}>
              <TextField fullWidth label="Content Type"
                variant="outlined"
                size="small"
                onChange={(e) => { setContentType(e.target.value) }}
                value={contentType}
              />
            </div>
            <div className={classes.formItem}>
              <TextField
                onChange={(e) => { setBody(e.target.value) }}
                size="small"
                value={body}
                style={{ marginTop: "10px" }}
                variant="outlined"
                multiline
                rows={5}
                fullWidth
                label="Body"
              />
            </div>
            <div className={classes.formItem}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Advanced Properties</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <div>
                    <TextField size="small" className={classes.inlineInput} label="Correlation ID" variant="outlined" value={correlationId} onChange={(e) => { setCorrelationId(e.target.value) }} />
                    <TextField size="small" className={classes.inlineInput} label="Message ID" variant="outlined" value={messageId} onChange={(e) => { setMessageId(e.target.value) }} />
                    <TextField size="small" className={classes.inlineInput} label="Partition key" variant="outlined" value={partitionKey} onChange={(e) => { setPartitionKey(e.target.value) }} />
                    <TextField size="small" className={classes.inlineInput} label="Session ID" variant="outlined" value={sessionId} onChange={(e) => { setSessionId(e.target.value) }} />
                    <TextField size="small" className={classes.inlineInput} label="Subject" variant="outlined" value={subject} onChange={(e) => { setSubject(e.target.value) }} />
                    <TextField size="small" className={classes.inlineInput} label="To" variant="outlined" value={to} onChange={(e) => { setTo(e.target.value) }} />
                    <TextField size="small" className={classes.inlineInput} label="Reply To" variant="outlined" value={replyTo} onChange={(e) => { setReplyTo(e.target.value) }} />
                    <TextField size="small" className={classes.inlineInput} label="Reply To Session ID" variant="outlined" value={replyToSessionId} onChange={(e) => { setReplyToSessionId(e.target.value) }} />
                  </div>
                  <div className={`${classes.formItem} ${classes.advancedPropertyContainer}`}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={enableSetTimeToLive}
                          onChange={(e) => { setEnableSetTimeToLive(e.target.checked) }}
                          name="visibility"
                          color="primary"
                        />
                      }
                      label="Time To Live"
                    />
                    {enableSetTimeToLive ?
                      <div className={classes.formItem}>
                        <TextField
                          size="small"
                          variant="outlined"
                          margin="dense"
                          label="Hours"
                          value={timeToLiveHours}
                          onChange={(e) => { setTimeToLiveHours(Number(e.target.value) < 0 ? 1 : Number(e.target.value)) }}
                          type="number"
                        />
                      </div>
                      : <></>
                    }
                  </div>
                  <div className={`${classes.formItem} ${classes.advancedPropertyContainer}`}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={enableSchedualMessage}
                          onChange={(e) => { setEnableSchedualMessage(e.target.checked) }}
                          name="visibility"
                          color="primary"
                        />
                      }
                      label="Schedule Message"
                    />
                    {enableSchedualMessage ?
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          variant="inline"
                          disablePast
                          value={schedualMessageAt}
                          onChange={(value) => setSchedualMessageAt(value)}
                        />
                      </MuiPickersUtilsProvider> : <></>}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={classes.formItem}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Custom Properties</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    {
                      customProperties.map(({ key, value }, index) =>
                        <Fragment key={index}>
                          <Grid item xs={6}>
                            <TextField className={classes.inlineInput} value={key} label="Name" fullWidth variant="outlined" size="small" onChange={(e) => { onCustomPropertyRenamed(index, e.target.value) }} />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField className={classes.inlineInput} value={value} label="Value" fullWidth variant="outlined" size="small" onChange={(e) => { onCustomPropertyChanged(index, e.target.value) }} />
                          </Grid>
                        </Fragment>)
                    }
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          </>
          : <>
              { /* Import files UI */ }
              <div className={classes.formItem}>
                  <Button component="label" onClick={(e) => { setMessagesToImport([]); e.target.value = ""; }} onChange={onImportFile} variant="outlined">
                      Choose Files
                      <input
                        accept='application/JSON'
                        type="file"
                        multiple
                        hidden
                      />
                  </Button>
                  <FormLabel style={{ marginLeft: "10px" }}>
                    {`${messagesToImport.length} messages to import`} 
                  </FormLabel>
              </div>
              <div className={classes.formItem}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={ignoreMessageId}
                      onChange={(e) => { setIgnoreMessageId(e.target.checked) }}
                      name="ignoreMessageId"
                      color="primary"
                    />
                  }
                  label="Generate Message Ids"
                />
              </div>
          </>
        }
        <div style={{ display: 'flex' }}>
          <Tooltip title={props.entityInfo.isValid ? "" : "Please make sure you fill entity information details correctly."} placement="right">
              <div style={{ display: 'inline-block', padding: "0px", margin: "0px" }}>
                <Button onClick={importMode ? importMessages : send} disabled={!props.entityInfo.isValid || isLoading || (importMode && messagesToImport.length <= 0)} variant="contained" color="primary" className={classes.button} endIcon={<Icon>send</Icon>}>
                  Send
                </Button>
              </div>
          </Tooltip>

          <FormControlLabel
            style={{ marginLeft: 'auto' }}
            control={
              <Switch
                disabled={!isLicenced}
                checked={importMode}
                onChange={(e) => { setImportMode(e.target.checked) }}
                name="importMode"
                color="primary"
              />
            }
            label="Import mode"
          />
        </div>
        
        {isLoading && !importMode
          ? <div style={{ marginLeft: theme.spacing(0.5), marginTop: theme.spacing(2) }} className={classes.formItem}>
            <CircularProgress color="secondary" />
          </div>
          : undefined
        }

        {isLoading && importMode
          ? <div style={{ marginTop: theme.spacing(2) }} className={classes.formItem}>
            <LinearProgress value={loadingProgress} valueBuffer={loadingProgress + 10 < 100 ? loadingProgress + 10 : 100} variant='buffer' />
          </div>
          : undefined
        }

      </form>
    </div>
  );
}


export { SendMessage };
