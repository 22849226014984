import { Buffer } from "buffer";
import jssha from "jssha";
  /**
   * Creates the sas token based on the provided information
   * @param {string | number} expiryInDays - Number of days after which the token will expire.
   * @param {string} [audience] - The audience for which the token is desired.
   * @param {string | Buffer} [hashInput] The input to be provided to hmac to create the hash.
   */
  export const createSasToken = (
    expiryInDays,
    audience,
    hashInput,
    saskeyName
  ) => {
    const expiry = Math.floor(Date.now() / 1000) + (expiryInDays * 24 * 60 * 60 * 60)
    audience = encodeURIComponent(audience);
    const keyName = encodeURIComponent(saskeyName);
    const stringToSign = audience + "\n" + expiry;
    hashInput = hashInput || this.key;
    let shaObj;
    if (Buffer.isBuffer(hashInput)) {
      shaObj = new jssha("SHA-256", "ARRAYBUFFER");
      shaObj.setHMACKey(hashInput, "ARRAYBUFFER");
      shaObj.update(Buffer.from(stringToSign));
    } else {
      shaObj = new jssha("SHA-256", "TEXT");
      shaObj.setHMACKey(hashInput, "TEXT");
      shaObj.update(stringToSign);
    }
    const sig = encodeURIComponent(shaObj.getHMAC("B64"));
    return {
      token: `SharedAccessSignature sr=${audience}&sig=${sig}&se=${expiry}&skn=${keyName}`,
      expiresOnTimestamp: expiry
    };
  }