import { ApplicationInsights } from '@microsoft/applicationinsights-web'

//For information: https://github.com/microsoft/ApplicationInsights-JS
export const appInsightsInitialize = () => {
    const externalDomains = ["management.azure.com", "login.microsoftonline.com"];
    const appInsights = new ApplicationInsights({ config: {
        connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
        loggingLevelConsole: 1,
        disableFetchTracking: false,
        disableAjaxTracking: false,
        enableAutoRouteTracking: false,
        enableUnhandledPromiseRejectionTracking: true,
        enableCorsCorrelation: true,
        disableCorrelationHeaders: false,
        correlationHeaderExcludedDomains: externalDomains
    } });
    const isLocalURL = (url) => externalDomains.filter((externalDomain) => url.indexOf(externalDomain) > -1).length === 0;
    var telemetryInitializer = (envelope) => {
    envelope.tags["ai.cloud.role"] = "Service Bus Cloud Explorer - Frontend App";
    envelope.tags["ai.cloud.roleInstance"] = "Service Bus Cloud Explorer - Frontend App";
    //For non local successfull traffic, we skip sending metrics for better security and customer privacy 
    if(envelope.baseType === "RemoteDependencyData" && !isLocalURL(envelope.baseData.target) && envelope.baseData.success === true ) {
        return false;
    }
    return true;
    }
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(telemetryInitializer);
    appInsights.trackPageView();
    window.appInsights = appInsights;
};
