const axios = require('axios').default;

export const activateSubscription = async (subscriptionId, tokenCredentials) => {
    const { token } = await tokenCredentials.getToken(["openid", "https://servicebus.cloudbricks.io/api"]);
    return (await axios.post(`/api/subscriptions/${subscriptionId}/activate`, {}, {
        headers: {
            //passing x-aswa-authorization is TMEPORARLY until: https://github.com/Azure/static-web-apps/issues/34
            //Authorization: `Bearer ${token}`,
            "x-aswa-authorization": `Bearer ${token}`,
        }
    })).data;
};