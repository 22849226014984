import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  DialogActions,
  TextField, 
  Typography, 
  Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormControl, 
  Button, 
  InputAdornment, 
  IconButton, 
  Dialog, 
  DialogContent, 
  DialogContentText, 
  DialogTitle  
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { createSasToken } from '../modules/createSasToken';
import { parseConnectionString } from '../modules/parseConnectionString';



const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    formItem: {
        marginBottom: theme.spacing(2),
    },
    radioContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    capitalize: {
      textTransform: "capitalize"
    }
  }));


  export default function AuthenticationInfo(props) {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [sasResourceUrl, setSasResourceUrl] = useState("");
    const [sasKeyName, setSasKeyName] = useState("");
    const [sasKey, setSasKey] = useState();
    const [sasExpiryInDays, setSasExpiryInDays] = useState(1);
    
    // related to SAS converter module
    const [convertToSasOpen, setConvertToSasOpen] = useState(false);
    const convertToSasTokenEnabled = props.entityInfo.authenticateVia === "connectionString" ? props.entityInfo.connectionString.length > 0 : props.entityInfo.sasKeyName.length > 0 && props.entityInfo.sasKey.length > 0;
    const sasTokenRef = useRef(null);

    const handleClickOpen = () => {
      setConvertToSasOpen(true);
    };
    const handleClose = () => {
      setConvertToSasOpen(false);
    };
    const handleCopyAndClose = () => {
      sasTokenRef.current.children[0].select();
      document.execCommand('copy');
      setConvertToSasOpen(false);
    };

    
    useEffect(() => {
      const isConnectionString = props.entityInfo.authenticateVia === "connectionString" && props.entityInfo.connectionString.length > 0;
      if(isConnectionString)
      {
        let parsedConnectionString;
        try {
          parsedConnectionString = isConnectionString ? parseConnectionString(props.entityInfo.connectionString) : {};
        } catch {}
        if(!parsedConnectionString) {
          setSasKeyName("");
          setSasKey("");
        } else {
          setSasKeyName(parsedConnectionString["SharedAccessKeyName"]);
          setSasKey(parsedConnectionString["SharedAccessKey"]);
        };
      } 
      else if(props.entityInfo.authenticateVia === "sasKey") {
        setSasKeyName(props.entityInfo.sasKeyName);
        setSasKey(props.entityInfo.sasKey);
      } 
      else {
        setSasKeyName("");
        setSasKey("");
      }
      setSasResourceUrl(`${props.entityInfo.namespaceUrl}${props.entityInfo.namespaceUrl.endsWith("/") ? "" : "/"}${props.entityInfo.entityType === "subscription" ? props.entityInfo.entityName.replace("/", "/subscriptions/") : props.entityInfo.entityName}`);
    }
    , [ props.entityInfo.connectionString,
        props.entityInfo.authenticateVia,
        props.entityInfo.entityName, 
        props.entityInfo.namespaceUrl, 
        props.entityInfo.sasKeyName, 
        props.entityInfo.sasKey,
        props.entityInfo.entityType
      ]);

    // end of stuff related to SAS converter module
    const onFieldChange = (name, value) => {
      props.entityInfo[name] = value;
      props.onEntityInfoChanged(props.entityInfo);
    }

    let authenticateUI;
    if(props.entityInfo.authenticateVia === "sasToken")
    {
        authenticateUI =  <div className={classes.formItem}>
                                <TextField 
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  onChange={(e) => { onFieldChange("sasToken", e.target.value) }} 
                                  value={props.entityInfo.sasToken} 
                                  label="SAS Token" 
                                  InputProps={{type: showPassword ? "text" : "password", endAdornment: <InputAdornment position="end">
                                      <IconButton onClick={() => { setShowPassword(!showPassword) }}>{!showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>}}
                                  helperText="Example: SharedAccessSignature sr=xxxxx" />
                            </div>
    }

    if(props.entityInfo.authenticateVia === "connectionString")
    {
        authenticateUI =  <div className={classes.formItem}>
                                <TextField 
                                  size="small"
                                  fullWidth 
                                  variant="outlined"
                                  onChange={(e) => { onFieldChange("connectionString", e.target.value) }} 
                                  value={props.entityInfo.connectionString} 
                                  label="Connection String" 
                                  InputProps={{type: showPassword ? "text" : "password", endAdornment: <InputAdornment position="end">
                                      <IconButton onClick={() => { setShowPassword(!showPassword) }}>{!showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>}}
                                 />
                            </div>
    }

    if(props.entityInfo.authenticateVia === "sasKey")
    {
        authenticateUI =  <>
                            <div className={classes.formItem}>
                                <TextField size="small" variant="outlined" fullWidth onChange={(e) => { onFieldChange("sasKeyName", e.target.value) }} value={props.entityInfo.sasKeyName} label="SAS Key Name" helperText="Example: Send-Only" />
                            </div>
                            <div className={classes.formItem}>
                                <TextField 
                                  size="small"
                                  variant="outlined"
                                  fullWidth 
                                  onChange={(e) => { onFieldChange("sasKey", e.target.value) }} 
                                  value={props.entityInfo.sasKey} 
                                  InputProps={{type: showPassword ? "text" : "password", endAdornment: <InputAdornment position="end">
                                      <IconButton onClick={() => { setShowPassword(!showPassword) }}>{!showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>}}
                                  label="SAS Key" />
                            </div>
                            
                          </>
    }


    if(props.entityInfo.authenticateVia === "aadClientIdAndSecret")
    {
        authenticateUI =  <>
                            <div className={classes.formItem}>
                                <TextField size="small" variant="outlined" fullWidth onChange={(e) => { onFieldChange("aadTenantId", e.target.value) }} value={props.entityInfo.aadTenantId} label="Tenant Id" helperText="Id of the Azure Active Directory Tenant" />
                            </div>
                            <div className={classes.formItem}>
                                <TextField size="small" variant="outlined" fullWidth onChange={(e) => { onFieldChange("aadCleintId", e.target.value) }} value={props.entityInfo.aadCleintId} label="Client Id" helperText="Claint Id of Service Principal/App Registeration" />
                            </div>
                            <div className={classes.formItem}>
                                <TextField 
                                  size="small"
                                  variant="outlined"
                                  fullWidth 
                                  onChange={(e) => { onFieldChange("aadCleintSecret", e.target.value) }} 
                                  value={props.entityInfo.aadCleintSecret} 
                                  InputProps={{type: showPassword ? "text" : "password", endAdornment: <InputAdornment position="end">
                                      <IconButton onClick={() => { setShowPassword(!showPassword) }}>{!showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>}}
                                  label="Client Secret"
                                  helperText="Secret of Service Principal/App Registeration" />
                            </div>
                            
                          </>
    }

    if(props.entityInfo.authenticateVia === "jwtToken")
    {
        authenticateUI =  <div className={classes.formItem}>
                                <TextField 
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  onChange={(e) => { onFieldChange("jwtToken", e.target.value) }} 
                                  value={props.entityInfo.jwtToken} 
                                  label="JWT Token" 
                                  InputProps={{type: showPassword ? "text" : "password", endAdornment: <InputAdornment position="end">
                                      <IconButton onClick={() => { setShowPassword(!showPassword) }}>{!showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>}}
                                  helperText="Example: Bearer xxxxx" />
                            </div>
    }

    return (
      <div className={classes.root}>
        <Typography variant="h6">
            Authentication
        </Typography>
        <form className={classes.form} noValidate autoComplete="off">
        <div>
            <FormControl style={{ marginTop: "0px" }} className={classes.radioContainer} component="fieldset">
                <RadioGroup row  aria-label="authenticateVia" name="authenticateVia">
                    {
                        props.isLoggedIn === true
                        ? <FormControlLabel value="yourIdentity" onChange={(e) => { onFieldChange("authenticateVia", e.target.value) }} checked={props.entityInfo.authenticateVia === 'yourIdentity'}  control={<Radio />} label="Your Identity" />
                        : undefined
                    }
                    {
                        props.isLoggedIn  === true
                        ? <FormControlLabel value="aadClientIdAndSecret" onChange={(e) => { onFieldChange("authenticateVia", e.target.value) }} checked={props.entityInfo.authenticateVia === 'aadClientIdAndSecret'}  control={<Radio />} label="Client Secret" />
                        : undefined
                    }
                    <FormControlLabel value="connectionString" onChange={(e) => { onFieldChange("authenticateVia", e.target.value) }} checked={props.entityInfo.authenticateVia === 'connectionString'}  control={<Radio />} label="Connection String" />
                    <FormControlLabel value="sasKey" onChange={(e) => { onFieldChange("authenticateVia", e.target.value) }} checked={props.entityInfo.authenticateVia === 'sasKey'} control={<Radio />} label="SAS Key" />
                    <FormControlLabel value="sasToken" onChange={(e) => { onFieldChange("authenticateVia", e.target.value) }} checked={props.entityInfo.authenticateVia === 'sasToken'} control={<Radio />} label="SAS Token" />
                    <FormControlLabel value="jwtToken" onChange={(e) => { onFieldChange("authenticateVia", e.target.value) }} checked={props.entityInfo.authenticateVia === 'jwtToken'}  control={<Radio />} label="JWT Token" />
                </RadioGroup>
            </FormControl>    
            {authenticateUI}

            {props.entityInfo.authenticateVia === "connectionString" || props.entityInfo.authenticateVia === "sasKey" ? 
                <div className={classes.toolsContainer}>
                <Button disabled={!convertToSasTokenEnabled} onClick={handleClickOpen} variant="contained" className={classes.button}>
                    Generate SAS Token
                </Button>
                <Dialog open={convertToSasOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Generate SAS Token</DialogTitle>
                    <DialogContent className={classes.convertToSasContainer}>
                    <DialogContentText>
                        Based on selected properties, generate a timely SAS token.
                    </DialogContentText>
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="dense"
                        id="sasTokenUrl"
                        value={sasResourceUrl}
                        onChange={(e) => {setSasResourceUrl(e.target.value)}}
                        label="Resource URL"
                        type="text"
                        fullWidth
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="dense"
                        id="sasTokenKeyName"
                        label="SAS Key Name"
                        value={sasKeyName}
                        onChange={(e) => {setSasKeyName(e.target.value)}}
                        type="text"
                        fullWidth
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="dense"
                        id="sasTokenKey"
                        label="SAS Key"
                        value={sasKey}
                        onChange={(e) => {setSasKey(e.target.value)}}
                        InputProps={{type: showPassword ? "text" : "password", endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => { setShowPassword(!showPassword) }}>{!showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                </InputAdornment>}}
                        type="text"
                        fullWidth
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="dense"
                        id="sasTokenExpiry"
                        label="Expiry in days"
                        value={sasExpiryInDays}
                        onChange={(e) => {setSasExpiryInDays(e.target.value)}}
                        InputProps={{ inputProps: { min: 1, max: 730 } }}
                        type="number"
                        fullWidth
                    />
                    <TextField
                        size="small"
                        multiline
                        variant="outlined"
                        margin="dense"
                        id="sasToken"
                        label="SAS Token"
                        InputProps={{
                        ref: sasTokenRef
                        }}
                        value={sasKeyName && sasKey && sasResourceUrl ? createSasToken(sasExpiryInDays, sasResourceUrl, sasKey, sasKeyName).token : ""}
                        rows={3}
                        type="text"
                        fullWidth
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCopyAndClose} color="primary">
                        Copy and close
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>
                </div>
                : undefined
            }
            </div>
        </form>
      </div>
    );
  }