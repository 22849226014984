export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#011C43',
      light: '#588ab8',
      dark: '#000123',
    },
    secondary: {
      main: '#EB651E',
    },
  },
};