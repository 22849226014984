import { createSasToken } from './createSasToken';
import { ServiceBusClient } from '@azure/service-bus';
import ClientSecretProxyCredentials from './ClientSecretProxyCredentials.js';
import StaticTokenCredentials from './StaticTokenCredentials';


const getConnectionString = (entityInfo) => {
    switch (entityInfo.authenticateVia) {
      case "connectionString":
        return entityInfo.connectionString;
      case "sasToken":
        return `Endpoint=${entityInfo.namespaceUrl.replace(':443', '')};SharedAccessSignature=${entityInfo.sasToken}`
      case "sasKey":
        const sasToken = createSasToken(1, `${entityInfo.namespaceUrl.replace(':443', '')}${entityInfo.namespaceUrl.endsWith("/") ? "" : "/"}${entityInfo.entityType === "subscription" ? entityInfo.entityName.replace("/", "/subscriptions/") : entityInfo.entityName}`, entityInfo.sasKey, entityInfo.sasKeyName)
        return `Endpoint=${entityInfo.namespaceUrl.replace(':443', '')};SharedAccessSignature=${sasToken.token}`
      default:
        throw Error();
    }
  };


const createServiceBusClient = (entityInfo, credentials, maxRetries = 0) => {
    const options = {
      retryOptions: {
        maxRetries,
        retryDelayInMs: 2000 // 2 seconds
      }
    };
    switch (entityInfo.authenticateVia) {
      case "yourIdentity":
        return new ServiceBusClient(entityInfo.namespaceUrl.replace(':443', '').replace('https://', ''), credentials, options);
      case "aadClientIdAndSecret":
        return new ServiceBusClient(entityInfo.namespaceUrl.replace(':443', '').replace('https://', ''), new ClientSecretProxyCredentials(entityInfo.aadTenantId, entityInfo.aadCleintId, entityInfo.aadCleintSecret, credentials), options);
      case "jwtToken":
        return new ServiceBusClient(entityInfo.namespaceUrl.replace(':443', '').replace('https://', ''), new StaticTokenCredentials(entityInfo.jwtToken), options);
        
      default:
        return new ServiceBusClient(getConnectionString(entityInfo), options);
    }
};


const createMessageReceiverAsync = async (serviceBusClient, entityInfo, receiverOptions, sessionId = null) => {
    switch (entityInfo.entityType) {
      case "queue":
        return sessionId ? serviceBusClient.createSessionReceiver(entityInfo.entityName, receiverOptions) 
        : Promise.resolve(serviceBusClient.createReceiver(entityInfo.entityName, receiverOptions));
      case "subscription":
        const segments = entityInfo.entityName.split("/");
        let topicName, subscriptionName;
        if (segments.length === 2) { 
          topicName = segments[0];
          subscriptionName = segments[1];
        } else {
          topicName = segments.slice(0, segments.length - 1).join("/");
          subscriptionName = segments[segments.length - 1];
        }
        return sessionId ? serviceBusClient.createSessionReceiver(topicName, subscriptionName , receiverOptions)
        : Promise.resolve(serviceBusClient.createReceiver(topicName, subscriptionName , receiverOptions));
      default:
        throw Error(`entityType ${entityInfo.entityType} doesn't support creating a message receiver.`);
    }
  };


const createMessageSender = (serviceBusClient, entityInfo) => serviceBusClient.createSender(entityInfo.entityName);

export { createServiceBusClient, createMessageReceiverAsync, createMessageSender };