import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { Tooltip, Switch, Select, MenuItem, Button, Typography, Snackbar } from '@material-ui/core';
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import { createServiceBusClient, createMessageReceiverAsync, createMessageSender } from "../modules/createServiceBusClient"
import { omit, uniqBy, endsWith } from "lodash";
import { SaveAlt, Replay } from '@material-ui/icons';
import { useMediaQuery } from "@material-ui/core";
import { saveAs } from 'file-saver';
import { Buffer } from "buffer";
import flatten from 'flat';
import { createEvent, createErrorEvent, createMessageEvent } from "../modules/logManager";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let isFileSaverSupported = false;
try {
    isFileSaverSupported = !!new Blob();
} catch {}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '600px'
    },
    grid: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '&&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
            color: theme.palette.primary.main
        }
    },
    cellText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word'
    },
    form: {
        marginBottom: theme.spacing(1),
        display: 'flex',
    },
    buttonsContainer: {
        marginLeft: 'auto',
        
    },
    syntaxHighlighter: {
        overflowX: "hidden!important",
        height: '600px',
        wordBreak: 'break-word'
    },
    buttonGroup: {
        height: "100%"
    },
    formControl: {
        marginRight: theme.spacing(1.3),
    },
    gridFloatingToolbar: {
        position: "absolute",
        zIndex: 1,
        marginTop: "3px",
        marginLeft: "3px",
        minWidth: "250px"
    },
    toolBarLable: {
        color: theme.palette.primary.main,
        fontSize: "small",
        fontWeight: "500",
    }
}));


export default function BrowseMessages({ entityInfo, credentials, selectedEntity, setErrorMessage, onMessageSelected, isLicenced, isDisplayed, onMessageEventReceived }) {
    const [fromDLQ, setFromDLQ] = useState(false);
    const peekBatchCount = 1000;
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const isNotLargeScreen = useMediaQuery(theme => theme.breakpoints.down("lg"));
    const minColumnWidth = isSmallScreen ? 30 : isNotLargeScreen ? 50 : 100;
    const getBodyAsText = (value) => {
        return value && Buffer.isBuffer(value) ? value.toString('utf8') : typeof(value) === "object" ? JSON.stringify(value) : value && value.toString ? value.toString() : value;
    }
    const renderCell = (params) => {
        const value = params.field === "body" ? params.value || "[Empty]":
        params.field === "subject" ? params.value || "[Empty]":
        endsWith(params.field, "Utc") && params.value && params.value.toLocaleString ? params.value.toLocaleString() :
        params.field.indexOf("Body.") === 0 && Array.isArray(params.value) ? getBodyAsText(params.value) :
        params.value && params.value.toString ? params.value.toString() : params.value;
        return <Tooltip title={ value && value.length >= 300 ? value.substring(0, 300) + "..." : value } >
            <span className={classes.cellText}>{ value }</span>
        </Tooltip>
    };
    const defaultColumns = [
        { field: 'subject', type: 'string', headerName: 'Subject', flex: 1, renderCell, minWidth: minColumnWidth, hide: true },
        { field: 'body', type: 'string', headerName: 'Body', flex: 2, renderCell, minWidth: minColumnWidth  },
        { field: 'enqueuedTimeUtc', type: 'dateTime', headerName: 'Enqueued Time', flex: 1, renderCell, minWidth: minColumnWidth  },
        { field: 'messageId', type: 'string', headerName: 'Message Id', flex: 1, renderCell, minWidth: minColumnWidth  },
        { field: 'deliveryCount', type: 'number', headerName: 'Delivery Count', flex: 1, renderCell, minWidth: minColumnWidth, hide: fromDLQ  },
        { field: 'lockToken', type: 'string', headerName: 'Lock Token', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'deadLetterReason', type: 'string', headerName: 'Dead Letter Reason', flex: 1, renderCell, minWidth: minColumnWidth, hide: !fromDLQ  },
        { field: 'deadLetterErrorDescription', type: 'string', headerName: 'Dead Letter Error Description', flex: 1, renderCell, minWidth: minColumnWidth, hide: !fromDLQ  },
        { field: 'deadLetterSource', type: 'string', headerName: 'Dead Letter Source', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'expiresAtUtc', type: 'dateTime', headerName: 'Expires At', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'lockedUntilUtc', type: 'dateTime', headerName: 'Locked Until', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'enqueuedSequenceNumber', type: 'number', headerName: 'Enqueued Sequence Number', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'sequenceNumber', type: 'number', headerName: 'Sequence Number', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'contentType', type: 'string', headerName: 'Content Type', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'correlationId', type: 'string', headerName: 'Correlation Id', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'sessionId', type: 'string', headerName: 'Session Id', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'replyToSessionId', type: 'string', headerName: 'Reply To Session Id', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'timeToLive', type: 'number', headerName: 'Time To Live', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'to', type: 'string', headerName: 'To', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'replyTo', type: 'string', headerName: 'Reply To', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'scheduledEnqueueTimeUtc', type: 'dateTime', headerName: 'Scheduled Enqueue At', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
        { field: 'id', type: 'string', headerName: 'Grid item Id', flex: 1, renderCell, minWidth: minColumnWidth, hide: true  },
    ];
    const classes = useStyles();
    const [allRows, setAllRows] = useState([]);
    const [columns, setColumns] = useState(defaultColumns);
    const [loading, setLoading] = React.useState(false);
    const [receiver, setReceiver] = React.useState(undefined);
    const [maxMessagesToLoad, setMaxMessagesToLoad] = React.useState("1000");
    const [loadedAllMessages, setLoadedAllMessages] = React.useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [openSentMessagesNotification, setOpenSentMessagesNotification] = useState(false);
    const flattenOptions = { safe: true };
    const prefixObjectProperties = (obj, prefix) => {
        let newObj = {};
        Object.keys(obj).forEach((key) => {
            newObj[prefix + key] = obj[key];
        })
        return newObj;
    }

    const getJsonColumns = (rows) => {
        const jsonColumns = [];
        const addedFields = new Set(columns.map(col => col.field));
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (row.body && typeof row.body === "object" && !Array.isArray(row.body) && !Buffer.isBuffer(row.body)) {
                const flat = flatten(row.body, flattenOptions);
                const keys = Object.keys(flat);
                for (let j = 0; j < keys.length; j++) {
                    const key = keys[j];
                    const field = `Body.${key}`;
                    if (!addedFields.has(field)) {
                        jsonColumns.push({
                            field,
                            type: 'string',
                            headerName: field,
                            flex: 1,
                            renderCell,
                            minWidth: minColumnWidth,
                            hide: true
                        });
                    }
                }
            }
        }
        return jsonColumns;
    }

    const replaySelectedMessages  = async () => {
        setLoading(true);
        const segments = entityInfo.entityName.split("/");
        let topicName;
        if (segments.length === 2) { 
          topicName = segments[0];
        } else {
          topicName = segments.slice(0, segments.length - 1).join("/");
        }
        const entityInfoToUse = entityInfo.entityType === "subscription" ? {
            ...entityInfo,
            entityType: "topic",
            entityName: topicName
        } : entityInfo; 
        const serviceBusClient = createServiceBusClient(entityInfoToUse, credentials, 3);
        const sender = createMessageSender(serviceBusClient, entityInfoToUse);
        let errored = false;
        for (const selectedRowId of selectionModel) {
            const message = allRows.filter(row => row.id === selectedRowId)[0];
            const messageToSend = {
                ...omit(message, ["deadLetterReason", "deadLetterErrorDescription", "expiresAtUtc", "enqueuedTimeUtc", "lockedUntilUtc", "enqueuedSequenceNumber", "enqueuedTimeUtc"]),
                applicationProperties : omit(message.applicationProperties, ["DeadLetterReason", "DeadLetterErrorDescription"])
            }
            const event = Object.assign(createEvent(entityInfoToUse, "send"), {
                messageToSend
            });
            try {
                await sender.sendMessages(messageToSend);
                onMessageEventReceived(createMessageEvent(event, messageToSend));
            } catch (error) {
                setErrorMessage("Error replaying a message, check logs for details. Aborting sending rest of messages");
                onMessageEventReceived(createErrorEvent(event, entityInfoToUse, error, { messageToSend }, selectedEntity.type === "subscription" ? selectedEntity.topic : selectedEntity));
                errored = true;
                break;
            }
            if (selectedEntity && selectedEntity.refresh) {
                selectedEntity.refresh();
            }
        }
        if(!errored){
            setOpenSentMessagesNotification(true);
        }
        await sender.close();
        await serviceBusClient.close();
        setLoading(false);
    }
    

    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <div style={{ marginLeft: "auto" }}>
            { !isNotLargeScreen? 
            <> 
                <GridToolbarFilterButton />
                <GridToolbarColumnsButton   />
                <GridToolbarDensitySelector />
            </>
            : <></> 
            }
            {
                fromDLQ && selectionModel.length > 0 ?
                <Tooltip title={`Resend ${selectionModel.length} selected messages to the ${entityInfo.entityType === "subscription" ? "parent topic" : entityInfo.entityType}`}>
                    <Button disabled={!isLicenced || loading} color="primary" size='small' startIcon={<Replay />} onClick={replaySelectedMessages}>
                        Replay
                    </Button>
                </Tooltip>
                : <></>
            }
            { 
                isFileSaverSupported ? 
                <Tooltip title={`Export ${allRows.length} messages to a JSON document`}>
                    <Button disabled={!isLicenced  || loading} color="primary" size='small' startIcon={<SaveAlt />} onClick={onSaveButnClick}>
                        Export
                    </Button>
                </Tooltip>
                : <></>
            }
            </div>
           
          </GridToolbarContainer>
        );
      }



    const onColumnVisibilityChange = ({field, isVisible}) => {
        setColumns((columns) => columns.map((col) => {
            if (col.field === field)
            {
                col.hide = !isVisible;
            }
            return col;
        }));
    }
    

    const createData = ({ messageId, subject, body, _rawAmqpMessage, timeToLive, expiresAtUtc, sequenceNumber, ...rest }) => {
        return Object.assign({
            id: `${messageId}-${sequenceNumber.toNumber().toString()}`,
            messageId,
            subject: subject,
            body: body,
            timeToLive,
            sequenceNumber: sequenceNumber.toNumber(), //TODO: test edge cases
            ...rest
        }, timeToLive? {
            expiresAtUtc
        }: {});
    }

    const currentReceiver = useRef();
    currentReceiver.current = receiver;
    
    useEffect(() => {
        (async () => {
            /*Notes:
            isDisplayed used to make sure that we relaod grid data based on selected entity after switching between insert/delete and browser tap
            */
            if (isDisplayed && credentials && entityInfo  && entityInfo.entityName && entityInfo.isValid && entityInfo.entityType !== "topic" && (!selectedEntity || !selectedEntity.forwardTo)
            ) {
                const client = createServiceBusClient(entityInfo, credentials, 3);
                const receiver = await createMessageReceiverAsync(client, entityInfo, Object.assign({}, 
                        fromDLQ ? { 
                            subQueueType: "deadLetter"
                        } : { }
                    ));
                setReceiver(receiver);
            } else {
                setReceiver(undefined);
            }
            onMessageSelected(null);
            setAllRows([]);
            setColumns(defaultColumns);
            setLoadedAllMessages(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityInfo, credentials, fromDLQ, onMessageSelected, selectedEntity, isDisplayed]);

    
    useEffect(() => {
        (async () => {
            if(receiver && (allRows.length <= maxMessagesToLoad || maxMessagesToLoad === 0) && !loadedAllMessages) {
                setLoading(true);
                try {
                    const messages = await receiver.peekMessages(peekBatchCount);
                    if(messages.length === 0) {
                        setLoadedAllMessages(true);
                    }
                    // only set rows if we receiver match with current receiver, as user might have selected another entity while messages as getting loaded
                    if (receiver === currentReceiver.current) {
                        // TODO: find out cases where we have some duplicate messages here and why we needed to add this uniqBy function
                        const newRows = messages.map(createData);
                        const newColumns = getJsonColumns(newRows);
                        setColumns((existingColumns) => [...existingColumns, ...newColumns]);
                        setAllRows((existingRows) => uniqBy([...existingRows, ...(newRows)], ({id}) => id));
                    } else if(messages.length > 0) {
                        // neither setLoadedAllMessages not setAllRows where called
                        // we just need to rurn off loading indicator
                        setLoading(false);
                    }
                } catch (error) {
                    setLoading(false);
                    if (error.code !== "UnauthorizedAccess")
                    {
                        console.error(error);
                        setErrorMessage(`Error while loading messages: ${error.message}`);
                    }
                }
            } else {
                setLoading(false);
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receiver, allRows.length, maxMessagesToLoad, loadedAllMessages, setErrorMessage])


    useEffect(() => {
        return () => {
            onMessageSelected(null);
        };
    }, [onMessageSelected]);


    const onRowClick = (params) => {
        const selectedMessage = omit(params.row, ["id", "sequenceNumber"]);
        selectedMessage.body = selectedMessage._body;
        onMessageSelected(omit(selectedMessage, ["_body", ...Object.keys(selectedMessage).filter(key => key.indexOf("Body.") === 0)]));
    }


    const onSelectionModelChange = (selectionModel) => {
        setSelectionModel(selectionModel);
        if(!selectionModel.length) onMessageSelected(null);
    }

    const onSaveButnClick = () => {
        try {
            const rowsToDownload = allRows.map((row) => omit(row, ["id"]));
            var fileToSave = new Blob([JSON.stringify(rowsToDownload, null, 4)], {
                type: 'application/json'
            });
            saveAs(fileToSave, "messages.json");
        } catch (error) { 
            //TODO: Add better handling for large number of messages
            if (error instanceof RangeError) {
                setErrorMessage("Available memory exceeded. Please reduce the number of messages to export.");
            } else {
                throw error;
            }
        }
    };

    
    return <div>
            <Snackbar open={openSentMessagesNotification} autoHideDuration={6000} onClose={() => { setOpenSentMessagesNotification(false) }}>
                <Alert onClose={() => { setOpenSentMessagesNotification(false) }} severity="success">
                    Replayed {selectionModel.length} messages successfully
                </Alert>
            </Snackbar>
            <div className={classes.gridFloatingToolbar}>
                
                    <Button variant='text' size='small' onClick={(e) => {
                                setFromDLQ(!fromDLQ);
                                e.preventDefault();
                            }}>
                            <Switch
                                size='small'
                                checked={fromDLQ}
                                onChange={(e) => {setFromDLQ(e.target.checked )}}
                                name="fromDLQ"
                                color="primary"
                                />
                                <Typography variant='button' color='primary'>From DLQ</Typography>
                    </Button>
                
                    <Button variant='text' >
                        <Select
                                variant='standard'
                                color='primary'
                                className={ classes.toolBarLable }
                                labelId="limit-label"
                                label="Limit"
                                value={maxMessagesToLoad}
                                onChange={(e) => 
                                    setMaxMessagesToLoad(e.target.value)
                                }
                            >
                        <MenuItem value={1000}>Limit: 1000</MenuItem>
                        <MenuItem disabled={!isLicenced} value={10000}>Limit: 10,000</MenuItem>
                        <MenuItem disabled={!isLicenced} value={50000}>Limit: 50,000</MenuItem>
                        <MenuItem disabled={!isLicenced} value={100000}>Limit: 100,000</MenuItem>
                        <MenuItem disabled={!isLicenced} value={0}>No limit</MenuItem>
                        </Select>
                    </Button>
                    
                    
            </div>
            <div className={classes.root}>
                <DataGridPro
                    className={classes.grid}
                    localeText={{
                        noRowsLabel: selectedEntity ? "No messages. Make sure you have read permissions on selected entity": "Select queue or subscription to load messages" 
                    }}
                    
                    rows={allRows.map(row => Object.assign({}, row, {
                        _body: row.body,
                        body: getBodyAsText(row.body),
                        //JSON handling commented out for perforamnce reasons
                        ...(row.body && !Buffer.isBuffer(row.body) && typeof row.body === 'object' && !Array.isArray(row.body) ? prefixObjectProperties(flatten(row.body, flattenOptions), "Body.") : {}),
                    }))}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    columns={columns}
                    paginationMode='client'
                    loading={loading}
                    filterMode='client'
                    sortingMode='client'
                    onRowClick={onRowClick}
                    onColumnVisibilityChange={onColumnVisibilityChange}
                    onSelectionModelChange={onSelectionModelChange}
                    
                />
            </div>
            
        </div>
}

export { BrowseMessages };
