import PromiseQueue from './PromiseQueue';

export const withAuthority = (request, tenantId) => {
  return Object.assign({}, request, { authority: `https://login.microsoftonline.com/${tenantId ? tenantId : 'organizations'}` });
}

export default class BrowserCredentials {
  publicApp;
  constructor(publicApp) {
    this.publicApp = publicApp;
  }
  isLoggedIn() {
    return this.publicApp.getAllAccounts().length > 0;
  }
  async getToken(scopes, options = {}) {
    if (!this.isLoggedIn()) {
      throw new Error("Sign-in is required before calling  getToken. Please use the provided PublicClientApplication instance to sign-in.");
    }
    const selectedAccount = this.publicApp.getActiveAccount() || this.publicApp.getAllAccounts()[0];
    const parameters = {
      account: selectedAccount,
      ...options,
      scopes: typeof scopes === 'string' ? scopes.split(" ") : scopes //some old versions of Azure SDK for js pass scopes as string. new ones as array
    };
    const getTokenInternal = async () => {
      try {
        return await this.publicApp.acquireTokenSilent(withAuthority(parameters, selectedAccount.tenantId));
      } catch (error) {
        try {
          const result = await this.publicApp.acquireTokenPopup(withAuthority(Object.assign({}, parameters, {
            redirectUri: `${window.location.protocol}//${window.location.host}/_blank.html`
          }), selectedAccount.tenantId));
          if(!this.publicApp.getActiveAccount()){
            this.publicApp.setActiveAccount(this.publicApp.getAllAccounts()[0]);
          }
          return result;
        } catch (error) {
          if(error && error.message && error.message.toLowerCase().indexOf("popup") > -1){
            return await this.publicApp.loginRedirect(withAuthority(parameters, selectedAccount.tenantId));
          }  
          throw error;
        }
      }
    }
    try {
      const result = await PromiseQueue.enqueue(getTokenInternal);
      return {
        token: result.accessToken,
        expiresOnTimestamp: result.expiresOn.getTime()
      };
    } catch (error) {
      if (error && error.message && error.message.indexOf("AADSTS650052: The app is trying to access a service '80a10ef9-8168-493d-abf9-3297c4ef6e3c'") > -1) {
        throw Error("Please make sure you have created any Azure Service Bus namespace or registered Microsoft.ServiceBus provider to any of your organization's Azure Subscriptions then refresh this page.")
      }
      throw error;
    }
  }
}