import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import companyLogo from '../icons/small-icon-light.png';
import { useMsal } from "@azure/msal-react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    logoText: {
      paddingLeft: theme.spacing(1),
      marginTop: theme.spacing(0.8)
    },
    logoImage:{
      height: 25,
      marginTop: theme.spacing(1)
    },
    headerContainer:{
      display: "flex",
      width: "100%"
    },
    account: {
      marginLeft: "auto",
      marginTop: theme.spacing(1.4),
      marginRight: theme.spacing(2)
    }
  }));



export default function Header (props) {
    const classes = useStyles();
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      event.preventDefault();
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const logOut = async () => {
      await instance.logoutRedirect({
        account: instance.getActiveAccount(),
        authority: `https://login.microsoftonline.com/${instance.getActiveAccount() ? instance.getActiveAccount().tenantId : 'organizations'}`
      });
    }

    return <>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <div className={classes.headerContainer}>
              <a href='/' title='Go to Service Bus Cloud Explorer'>
                <img alt="Cloudbricks.io logo" src={companyLogo} className={classes.logoImage} />
              </a>
              <Typography className={classes.logoText} variant="h6" noWrap>
                Service Bus Cloud Explorer
              </Typography>
              {
                account ?               
                        <div className={classes.account}>
                          <Link className={classes.usename} href="#" onClick={handleClick} color="inherit">
                            {account.username}
                          </Link>
                          <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            classes={classes.accountMenue}
                            anchorOrigin={{ horizontal: 'left', vertical:'bottom' }}
                          >
                            <MenuItem onClick={logOut}>Logout</MenuItem>
                          </Menu>
                        </div> 
                : <></>
              }

            </div>
          </Toolbar>
        </AppBar>
    </>
};