import { SvgIcon } from '@material-ui/core';
import React from 'react';


export const NamespaceIcon = (props) => {
    return (
      <SvgIcon viewBox="0 0 18 18" {...props}>
        <defs>
            <linearGradient id="a" x1="9" y1="10.3" x2="9" y2="13.2" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#005ba1"/>
                <stop offset=".26" stopColor="#00589d"/>
                <stop offset=".53" stopColor="#004f90"/>
                <stop offset=".8" stopColor="#003f7c"/>
                <stop offset="1" stopColor="#003067"/>
            </linearGradient>
        </defs>
        <path fill="#50e6ff" d="M13.39 7.4h-.01v-.02h-.02L9 4.41 4.63 7.38h-.02v5.82h8.77V7.4h.01z"/>
        <path d="M4.61 7.4v5.8L9 10.3z" fill="#32bedd"/>
        <path d="M13.38 7.4L9 10.3l4.38 2.9z" fill="#198ab3"/>
        <path fill="url(#a)" d="M8.99 10.3l-4.38 2.89v.01h8.77v-.01L8.99 10.3z"/>
        <g fill="#999">
            <path d="M1.07 1.43h1.29V5a.29.29 0 01-.29.29H.79A.29.29 0 01.5 5V2a.57.57 0 01.57-.57z"/>
            <path d="M1.07 1.43h1.29V5a.29.29 0 01-.29.29H.79A.29.29 0 01.5 5V2a.57.57 0 01.57-.57z" opacity=".5"/>
        </g>
        <g fill="#999">
            <path d="M15.64 1.43h1.29a.57.57 0 01.57.57v3a.29.29 0 01-.29.29h-1.29a.29.29 0 01-.28-.29V1.43z"/>
            <path d="M15.64 1.43h1.29a.57.57 0 01.57.57v3a.29.29 0 01-.29.29h-1.29a.29.29 0 01-.28-.29V1.43z" opacity=".5"/>
        </g>
        <path d="M17.5 2v1.25H.5V2a.57.57 0 01.57-.57h15.87a.57.57 0 01.56.57z" fill="#949494"/>
        <g fill="#999">
            <path d="M.79 12.68h1.29a.29.29 0 01.29.29v3.6h-1.3A.57.57 0 01.5 16v-3a.29.29 0 01.29-.32z"/>
            <path d="M.79 12.68h1.29a.29.29 0 01.29.29v3.6h-1.3A.57.57 0 01.5 16v-3a.29.29 0 01.29-.32z" opacity=".5"/>
        </g>
        <g fill="#999">
            <path d="M15.92 12.68h1.29a.29.29 0 01.29.29v3a.57.57 0 01-.57.57h-1.29V13a.29.29 0 01.28-.32z"/>
            <path d="M15.92 12.68h1.29a.29.29 0 01.29.29v3a.57.57 0 01-.57.57h-1.29V13a.29.29 0 01.28-.32z" opacity=".5"/>
        </g>
        <path d="M.5 16v-1.25h17V16a.57.57 0 01-.57.57H1.07A.57.57 0 01.5 16z" fill="#949494"/>
      </SvgIcon>
    );
}

export default NamespaceIcon;