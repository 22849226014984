import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { maxNumberOfMessages } from './ReceiveMessages';
import MuiAlert from '@material-ui/lab/Alert';
import { Button, Tooltip, TextField, FormControlLabel, Switch, Snackbar, CircularProgress } from '@material-ui/core/';
import { Replay } from '@material-ui/icons';
import { createServiceBusClient, createMessageReceiverAsync, createMessageSender } from "../modules/createServiceBusClient"
import { createEvent, createErrorEvent, createMessageEvent } from "../modules/logManager";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    root: {
    },
    form: {
    },
    formItem: {
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
    },
    inlineInput: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

export default function ReplayMessages(props) {
    const theme = useTheme();
    const classes = useStyles();
    const { entityInfo, credentials, selectedEntity = null, onMessageEventReceived, setErrorMessage } = props;
    const [numberOfMessages, setNumberOfMessages] = useState(1);
    const [deleteFromSource, setDeleteFromSource] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const [receiveResult, setReceiveResult] = useState("Received messages for replay successfully");

    const handleNotificationClose = (event, reason) => {
        setOpenNotification(false);
    };

    const receiveMode = "peekLock";

    const receiveEvent = Object.assign({},
        createEvent(entityInfo, receiveMode),
        {
            entityName: `${entityInfo.entityName}/$deadLetterQueue`
        }
    );

    const completeEvent = Object.assign({},
        createEvent(entityInfo, "complete"),
        {
            entityName: `${entityInfo.entityName}/$deadLetterQueue`
        }
    );

    const subscriptionTopicEntityInfo = entityInfo.entityType === "subscription" ? Object.assign({}, entityInfo, {
        entityType: "topic",
        entityName: entityInfo.entityName.split("/")[0]
    }) : undefined;


    const sendEvent = Object.assign({},
        createEvent(subscriptionTopicEntityInfo || entityInfo, "send"),
        {
            entityName: `${(subscriptionTopicEntityInfo || entityInfo).entityName}`
        }
    );

    // callback to run when any error occurs while any receive operation is going
    const onReceiveError = (error) => {
        setErrorMessage("Error sending message, check log for details.")
        onMessageEventReceived(createErrorEvent(receiveEvent, entityInfo, error, {} , selectedEntity));
    };

    const replay = async () => {
        setIsLoading(true);
        const serviceBusClient = createServiceBusClient(entityInfo, credentials);
        const receiverOptions = {
            receiveMode,
            subQueueType: "deadLetter"
        };
        try {
            // create receiver
            const receiver = await createMessageReceiverAsync(serviceBusClient, entityInfo, receiverOptions);
            // create sender
            const sender = subscriptionTopicEntityInfo ? createMessageSender(serviceBusClient, subscriptionTopicEntityInfo) : createMessageSender(serviceBusClient, entityInfo);
            // receive specific number of messages
            const receivedMessages = await receiver.receiveMessages(numberOfMessages < 1 ? 1 : numberOfMessages, {
                maxWaitTimeInMs: 4000,
            });

            // callback to run when message is received by a message receiver
            // if receiveMode is peekLock, the handler also abandon the message
            const onMessageReceived = (receiver) => async (message) => {
                onMessageEventReceived(createMessageEvent(receiveEvent, message));
                if (!deleteFromSource) {
                    try {
                        await receiver.abandonMessage(message);
                    } catch (error) {
                        onMessageEventReceived(createErrorEvent(Object.assign({}, receiveEvent, { operation: "abandon" }), entityInfo, error, { message }, selectedEntity));
                    }
                }
                try {
                    await sender.sendMessages(message);
                    onMessageEventReceived(createMessageEvent(sendEvent, message));
                } catch (error) {
                    onMessageEventReceived(createErrorEvent(sendEvent, entityInfo, error, { message }, selectedEntity));
                }
                if (deleteFromSource) {
                    try {
                        await receiver.completeMessage(message);
                        onMessageEventReceived(createMessageEvent(completeEvent, message));
                    } catch (error) {
                        onMessageEventReceived(createErrorEvent(Object.assign({}, receiveEvent, { operation: "complete" }), entityInfo, error, { message }, selectedEntity));
                    }
                }
            };

            // no messages
            if (receivedMessages.length === 0) {
                setReceiveResult("No messages available in DLQ for replay")
                setOpenNotification(true);
                onMessageEventReceived(Object.assign({}, receiveEvent, {
                    time: new Date(),
                }));
            }
            else {
                // received messages
                setReceiveResult(`Successfully received ${receivedMessages.length} messages from DLQ to replay.`)
                setOpenNotification(true);
                // process received messages
                await Promise.all(receivedMessages.map(onMessageReceived(receiver)));
            }
            // refresh if there is a selected entity
            if(selectedEntity && selectedEntity.refresh)
            {
                selectedEntity.refresh();
            }
            // cleanup after receive specific number of messages
            setIsLoading(false);
            await Promise.all([receiver.close(), sender.close()]);
            await serviceBusClient.close();
        } catch (error) {
            onReceiveError(error);
            setIsLoading(false);
            await serviceBusClient.close();
        }

    };


    return (
        <div className={classes.root}>
            <Snackbar open={openNotification} autoHideDuration={6000} onClose={handleNotificationClose}>
                <Alert onClose={handleNotificationClose} severity="success">
                    {receiveResult}
                </Alert>
            </Snackbar>
            <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.formItem}>
                    <TextField
                        size="small"
                        variant="outlined"
                        margin="dense"
                        label="Number of messages to replay"
                        value={numberOfMessages}
                        onChange={(e) => {setNumberOfMessages(Number(e.target.value) > maxNumberOfMessages ? maxNumberOfMessages : (Number(e.target.value) < 0 ? 1 : Number(e.target.value)))}}
                        InputProps={{ inputProps: { min: 1, max: maxNumberOfMessages } }}
                        type="number"
                        fullWidth
                    />
                </div>

                <div className={classes.formItem}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={deleteFromSource}
                                onChange={(e) => { setDeleteFromSource(e.target.checked) }}
                                name="deleteFromSource"
                                color="primary"
                            />
                        }
                        label="Delete from DLQ"
                    />
                </div>

                <Tooltip title={entityInfo.isValid ? "" : "Please make sure you fill entity information details correctly."} placement="right">
                    <div style={{ display: "inline-block", padding: "0px", margin: "0px" }}>
                        <Button onClick={replay} disabled={!entityInfo.isValid || isLoading} variant="contained" color="primary" className={classes.button} endIcon={<Replay />}>
                            Replay
                    </Button>
                    </div>
                </Tooltip>
                {isLoading
                    ? <div style={{ marginLeft: theme.spacing(0.5), marginTop: theme.spacing(2) }} className={classes.formItem}>
                        <CircularProgress color="secondary" />
                    </div>
                    : undefined
                }
            </form>
        </div>
    )
}

export { ReplayMessages };