const createEvent = (entityInfo, operation) => Object.assign({}, {
    namespace: entityInfo.namespaceUrl.replace('https://', '').replace(".servicebus.windows.net/", "").replace(".servicebus.windows.net", ""),
    entityName: entityInfo.entityName,
    operation: operation,
    entityType: entityInfo.entityType,
  }
);

const removeClutterFromMessage = (message) => {
    const messageInLog = Object.assign({}, message);
    // delete clutter properties, that might not be important to log
    delete messageInLog._amqpAnnotatedMessage;
    delete messageInLog._context;
    delete messageInLog._entityPath;
    delete messageInLog._delivery;
    delete messageInLog._rawAmqpMessage;
    delete messageInLog.delivery;
    messageInLog.sequenceNumber = messageInLog.sequenceNumber?.toNumber ? messageInLog.sequenceNumber.toNumber() : messageInLog.sequenceNumber;
    return messageInLog;
}

const createMessageEvent = (baseEvent, message) => {
    const messageInLog = removeClutterFromMessage(message);
    return (Object.assign({}, baseEvent, {
        time: new Date(),
        message: messageInLog
    }));
}

const normalizeError = (error) => {
    if (error instanceof Error) {
        return error.message || (error.toString? error.toString() : "Unknown error that cannot be presented as text")
    }
    if (typeof error === "object") {
        try {
            if (JSON.stringify(error) && Object.keys(error).length > 0) { // if it can be presented as string?
                return error;
            }
        } catch (error) {
            return error.toString ? error.toString() : "Unknown error that cannot be presented as text"
        }
    }
    return error;
}

const createErrorEvent = (baseEvent, entityInfo, error, errorContext= {}, selectedEntity = null) => {
    const simpleErrorContext = Object.assign({}, errorContext, errorContext.message ? { 
        message: removeClutterFromMessage(errorContext.message)
    }: {});
    const errorEvent = Object.assign({}, baseEvent, {
        time: new Date(),
        error: {
            operation: baseEvent.operation,
            ...simpleErrorContext,
            error: normalizeError(error),
            entityInfo: {
                ...entityInfo,
                connectionString: entityInfo.connectionString ? "******" : "",
                sasToken: entityInfo.sasToken ? "******" : "",
                sasKey: entityInfo.sasKey ? "******" : "",
            },
            selectedEntity,
        },
    
    });
    return errorEvent;
};


export { createEvent, createMessageEvent, createErrorEvent, removeClutterFromMessage };