import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { makeStyles } from '@material-ui/core/styles';
import Header from './components/Header';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { themeOptions } from './modules/themeOptions';
import AppPage from './pages/AppPage';
import AccountPage from './pages/AccountPage';
import { appInsightsInitialize } from './modules/appInsightsInitialize';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import BrowserCredentials, { withAuthority } from './modules/BrowserCredentials';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { LicenseInfo } from '@mui/x-license-pro';

// mui-x license key
LicenseInfo.setLicenseKey('219ff28d7ef9fe481d8a162b59bd9bafT1JERVI6Nzg0MjYsRVhQSVJZPTE3MzExMzc4NTg2NjgsS0VZVkVSU0lPTj0x');

const params = new URLSearchParams(window.location.search);
const urlTenantId = params.get("tenantId");
const urlTenantIdEncoded = urlTenantId ? encodeURIComponent(urlTenantId) : null;

const reload = () => { 
  window.localStorage.clear();	
  window.sessionStorage.clear();	
  window.location.reload();
};

// MSAL configuration
const configuration = {
  auth: {
      clientId: process.env.REACT_APP_AAD_APP_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${ urlTenantIdEncoded || "organizations"}`,
      postLogoutRedirectUri: `https://${ window.location.hostname }${ urlTenantIdEncoded ? `?tenantId=${urlTenantIdEncoded}` : "" }`,
  },
  cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
      secureCookies: true
  }
};
const pca = new PublicClientApplication(configuration);
// a wrapper that just provides a getToken interface for azure js SDK
pca.browserCredentials = new BrowserCredentials(pca);

const request = {
  scopes: ["openid", "https://servicebus.cloudbricks.io/api"],
  extraScopesToConsent: window.location.pathname.toLowerCase() === "/account" ? [] : ["https://management.azure.com/user_impersonation", "https://servicebus.azure.net/user_impersonation"],
  prompt: 'select_account'
}

appInsightsInitialize();
const theme = createTheme(themeOptions);
const useStyles = makeStyles((theme) => ({
   // necessary for content to be below app bar
   toolbar: theme.mixins.toolbar,
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Root() {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const [loggedInAccount, setLoggedInAccount] = useState(undefined);
  
  const handleErrorClose = (_e, reason) => {
    // sticky error if its a login error as nothing else will be on the page
    if(reason === "clickaway" && errorMessage.indexOf("There was an error while logging you in.") === 0){
      return;
    }
    setErrorMessage("");
  };
  
  useEffect(() => {
    const loginAsync = async () => {
      try {
        const resultFromInteractive = await pca.handleRedirectPromise();
        let resultFromSilent;
        const matchedAccounts = pca.getAllAccounts()
          .filter(account => account.idTokenClaims && account.idTokenClaims.aud === configuration.auth.clientId);
        if (matchedAccounts.length === 0) {
          await pca.loginRedirect(request);
        } else if (matchedAccounts.length === 1) {
          if(!pca.getActiveAccount() && matchedAccounts.length > 0) {
            pca.setActiveAccount(matchedAccounts[0]);
          } 
          if(!resultFromInteractive)
          {
            try {
              resultFromSilent = await pca.acquireTokenSilent(withAuthority(request, pca.getActiveAccount().tenantId));
            } catch (error) {
              await pca.loginRedirect(withAuthority(request, pca.getActiveAccount().tenantId));
            }
          }
        } else {
          window.appInsights.trackEvent({
            name: "Multiple logged-in accounts detected",
            properties: pca.getAllAccounts().map(account => { 
              return { 
                username: account.username, 
                tenantId: account.tenantId 
              } 
            })
          });
          reload();
          return;
        }
        const account = resultFromInteractive || resultFromSilent;
        // users wants to login with a different account
        if(urlTenantId && account.tenantId !== urlTenantId){
          reload();
          return;
        } 
        setLoggedInAccount(account);
      } catch (error) {
        window.appInsights.trackException({
          exception: error,
          properties: {
              process: "logging in user in index.js"
          }
        });
        setLoggedInAccount(null);
        if (error && error.message && error.message.indexOf("AADSTS650052: The app is trying to access a service '80a10ef9-8168-493d-abf9-3297c4ef6e3c'") > -1)
        {
          setErrorMessage("Error: Please make sure you have created any Azure Service Bus namespace or registered Microsoft.ServiceBus provider to any of your organization's Azure Subscriptions then refresh this page.")
        } else {
          setErrorMessage("There was an error while logging you in. Refreshing the page might fix the problem. " + error.message || (error.toString ? error.toString() : ""));
        }
      }
    }
    loginAsync();
  }, []);

  return (
    <ThemeProvider theme={theme}>
    <div className={classes.root}>
      <Snackbar open={errorMessage !== ""} autoHideDuration={errorMessage.indexOf("There was an error while logging you in.") === 0 ? null : 7000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
        { loggedInAccount ? <>
          <MsalProvider instance={pca}><Header /></MsalProvider>
          <Router>
            <Routes>
                <Route exact path="/" element={<MsalProvider instance={pca}><AppPage loggedInAccount={loggedInAccount} setErrorMessage={setErrorMessage} /></MsalProvider>} />
                <Route exact path="/account" element={<MsalProvider instance={pca}><div className={classes.toolbar}></div><AccountPage loggedInAccount={loggedInAccount} setErrorMessage={setErrorMessage} /></MsalProvider>} />
            </Routes>
          </Router>
        </> 
        : <></>
        }
    </div>
    </ThemeProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);