import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TreeView, TreeItem } from '@material-ui/lab';
import { ServiceBusManagementClient } from "@azure/arm-servicebus";
import { FilterList, Clear, Email, ArrowDropDown, ArrowRight, HourglassFull } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Box, Tooltip, Typography, TextField, Grid, IconButton, InputAdornment, CircularProgress } from '@material-ui/core';
import AzureIcon from '../icons/azure';
import NamespaceIcon from '../icons/namespace';
import { blue, purple, deepPurple } from '@material-ui/core/colors';
import { SubscriptionClient } from '@azure/arm-resources-subscriptions';
import MuiAlert from '@material-ui/lab/Alert';


const maxCharacterLength = 35;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(.5),
  },
  spinner: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(3),
  },
  filterContainer: {
    marginBottom: theme.spacing(2),
  },
  clearFilterIcon: {
    padding:2,
  },
  alert: {
    margin: theme.spacing(3),
  }
}));

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[250]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
    '&$selected > $content': {
      backgroundColor: `${theme.palette.grey[300]}`,
    }
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: `calc(var(--node-depth) * ${theme.spacing(1.5)}px)`,
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    wordBreak: 'break-word'
  },
  labelInfo: {
    
  }
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {labelText, labelIcon: LabelIcon, labelIconColor="inherit", labelIconStyle, labelInfo, color, bgColor, depth = 1, labelTooltip = "", ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon fontSize="small" color={labelIconColor} style={labelIconStyle} className={classes.labelIcon} />
          <Tooltip title={labelTooltip} placement="top-start">
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          </Tooltip>
          <Typography variant="caption" className={classes.labelInfo} color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
        "--node-depth": depth
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};


// sort by entity name
const itemSort = (a, b) => {
  var nameA = a.name.toUpperCase();
  var nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}


export default function EntityBrowser(props) {
  const { credentials, onEntitySelected, setErrorMessage, selectedEntity, onEntityAdditionalInfoProvided } = props;
  const classes = useStyles();
  const [subscriptionsWithNamespaces, setSubscriptionsWithNamespaces] = useState(undefined);
  const [filter, setFilter] = useState("");
  const [selectedNodeId, setSelectedNodeId] = useState();
  const [subscriptions, setSubscriptions] = useState([]);

  const selectedNodeIdRef = useRef();
  selectedNodeIdRef.current = selectedNodeId;

  const subscriptionsWithNamespacesRef = useRef();
  subscriptionsWithNamespacesRef.current = subscriptionsWithNamespaces;

  // filter by entity name filter text
  const entityNameFilter = ({ name }) => { 
    if (filter.length <= 0) return true
    return name.indexOf(filter) > -1;
  };

  // auto update selected entity based on state changes (i.e. new message counts after refresh) if applicable
  useEffect(() => {
    let update = {};
    if(selectedEntity)
    {
      const { type, namespaceId, subscriptionId, id } = selectedEntity;
      switch (type) {
        case "topic":
          update = subscriptionsWithNamespaces[subscriptionId].namespaces[namespaceId].topics?  {
            countDetails: subscriptionsWithNamespaces[subscriptionId].namespaces[namespaceId].topics[id].countDetails
          } : {};
        break;
        case "queue":
          update = subscriptionsWithNamespaces[subscriptionId].namespaces[namespaceId].queues? {
            countDetails: subscriptionsWithNamespaces[subscriptionId].namespaces[namespaceId].queues[id].countDetails
          } : {};
        break;
        case "subscription":
          const { topic } = selectedEntity;
          update = subscriptionsWithNamespaces[subscriptionId].namespaces[namespaceId].topics? {
            countDetails: subscriptionsWithNamespaces[subscriptionId].namespaces[namespaceId].topics[topic.id].subscriptions.find((subscription) => subscription.id === id).countDetails,
          }: {};
        break;
        default:
          break;
      }
      onEntityAdditionalInfoProvided(update);
    }
  // react-hooks/exhaustive-deps is disabled as this is one-way update flow
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [subscriptionsWithNamespaces]);

  useEffect(() =>{
    const doAsync = async () => {
      try {
        const subscriptionClient = new SubscriptionClient(credentials);
        const subscriptions = subscriptionClient.subscriptions.list();
        const subscriptionArray = [];
        for await (const subscription of subscriptions){
            subscriptionArray.push(subscription);
        }
        setSubscriptions(subscriptionArray.filter(sub => (sub.displayName !== "Access to Azure Active Directory")));
      } catch (error) {
        setErrorMessage("Error loading subscriptions, " + error.toString() );
        throw error;
      }
    }
    doAsync();
  }, [credentials, setErrorMessage])

  useEffect(() => {
    const doAsync = async () => {
      if (credentials && subscriptions)
      {
        try {
          await Promise.all(subscriptions.map(async (subscription) => {
            const context = new ServiceBusManagementClient(credentials, subscription.subscriptionId)
            const namespacesIterator = context.namespaces.list();
            const namespaces = [];
            for await (const namespace of namespacesIterator) {
              namespaces.push(namespace);
            }
            if(namespaces.length > 0)
            {
              const item = {
                id: subscription.subscriptionId,
                name: subscription.displayName,
                namespaces: namespaces.reduce((namespaces, namespace) => {
                  return Object.assign(namespaces, {
                    [namespace.id]: {
                      subscriptionId: subscription.subscriptionId,
                      serviceBusEndpoint: namespace.serviceBusEndpoint,
                      context: () => context,
                      ...namespace
                    }
                  })
                }, {}),
              };
              setSubscriptionsWithNamespaces((previous) => ({
                ...(previous || {}),
                [subscription.subscriptionId]: item,
              }));
            }
          }));
        } catch (error) {
          setErrorMessage("Error loading namespaces, " + error.toString() );
          throw error;
        }
      }
    };
    doAsync();
  }, [subscriptions, credentials, setErrorMessage]);


  const setIsNamespaceLoading = (namespace, isLoading) => {
    setSubscriptionsWithNamespaces((previous) => ({
      ...previous,
      [namespace.subscriptionId]: {
        ...previous[namespace.subscriptionId],
        namespaces: { 
          ...previous[namespace.subscriptionId].namespaces,
          [namespace.id]: {
            ...previous[namespace.subscriptionId].namespaces[namespace.id],
            isLoading
          }
        },
      }
    }));
  };


  const onNamespaceSelected = async (namespace) => {
    try {
      const refresh = () => { 
        // call onNamespaceSelected if not namespace already loading
        if(!subscriptionsWithNamespacesRef.current[namespace.subscriptionId].namespaces[namespace.id].isLoading) {
          onNamespaceSelected(namespace);
        }
      };
      setIsNamespaceLoading(namespace, true);
      const [ , , , , resourceGroupName] = namespace.id.split("/");
      const topicsIterator = namespace.context().topics.listByNamespace(resourceGroupName, namespace.name);
      const queuesIterator = namespace.context().queues.listByNamespace(resourceGroupName, namespace.name);
      const loadTopics = async () => {
        for await (const topics of topicsIterator.byPage()) {
          setSubscriptionsWithNamespaces((previous) => ({
            ...previous,
            [namespace.subscriptionId]: {
              ...previous[namespace.subscriptionId],
              namespaces: { 
                ...previous[namespace.subscriptionId].namespaces,
                [namespace.id]: {
                  ...previous[namespace.subscriptionId].namespaces[namespace.id],
                  topics: {
                    ...(previous[namespace.subscriptionId].namespaces[namespace.id].topics || {}),
                    ...(topics.reduce((topics, topic) => Object.assign({}, topics, {
                      ...topics,
                      [topic.id]: {
                        ...((previous[namespace.subscriptionId].namespaces[namespace.id].topics || {})[topic.id] || {}),
                        ...topic,
                        refresh,
                        namespace: namespace
                      }
                    }), {})) 
                  }
                }
              },
            }
          }));
        }
      }
      const loadQueues = async () => {
        for await (const queues of queuesIterator.byPage()) {
          setSubscriptionsWithNamespaces((previous) => ({
            ...previous,
            [namespace.subscriptionId]: {
              ...previous[namespace.subscriptionId],
              namespaces: { 
                ...previous[namespace.subscriptionId].namespaces,
                [namespace.id]: {
                  ...previous[namespace.subscriptionId].namespaces[namespace.id],
                  queues: {
                    ...(previous[namespace.subscriptionId].namespaces[namespace.id].queues || {}),
                    ...(queues.reduce((queues, queue) => Object.assign({}, queues, {
                      ...queues,
                      [queue.id]: {
                        ...((previous[namespace.subscriptionId].namespaces[namespace.id].queues || {})[queue.id] || {}),
                        ...queue,
                        refresh,
                        namespace: namespace
                      }
                    }), {}))
                  }
                }
              },
            }
          }));
        }
      }
      await Promise.all([loadTopics(), loadQueues()]);
      setIsNamespaceLoading(namespace, false);
    } catch (error) {
      setIsNamespaceLoading(namespace, false);
      setErrorMessage("Error selecting namespace or loading queues and topics, " + error.toString() );
      throw error;
    }
  }


  const onTopicSelected = async (topic) => {
    try {
      const { namespace, refresh } = topic;
      const loadSubscriptions = async (topic) => {
        const [ , , , , resourceGroupName] = namespace.id.split("/");
        const subscriptionsIterator = namespace.context().subscriptions.listByTopic(resourceGroupName, namespace.name, topic.name);
        const subscriptions = [];
        for await (const subscription of subscriptionsIterator) {
          subscriptions.push(subscription);
        }
        setSubscriptionsWithNamespaces((previous) => ({
          ...previous,
          [namespace.subscriptionId]: {
            ...previous[namespace.subscriptionId],
            namespaces: { 
              ...previous[namespace.subscriptionId].namespaces,
              [namespace.id]: {
                ...previous[namespace.subscriptionId].namespaces[namespace.id],
                topics: {
                  ...previous[namespace.subscriptionId].namespaces[namespace.id].topics,
                  [topic.id]: {
                    ...previous[namespace.subscriptionId].namespaces[namespace.id].topics[topic.id],
                    subscriptions: subscriptions.map((subscription) => Object.assign({}, subscription, {
                      topic: topic,
                      refresh: () => loadSubscriptions(topic)
                    }))
                  }
                }
              }
            }
          }
        }));
      };
      onEntitySelected({
        namespaceName: namespace.name,
        namespaceId: namespace.id,
        namespaceUrl: namespace.serviceBusEndpoint,
        subscriptionId: namespace.subscriptionId,
        ...topic,
        // combine existing topic refresh with a callback to reload subscriptions of that topic to update message counts
        refresh: () => {
          refresh();
          loadSubscriptions(topic);
        },
        type: "topic",
      });
      await loadSubscriptions(topic);
    } catch (error) {
      setErrorMessage("Error selecting topic or loading subscriptions, " + error.toString() );
      throw error;
    }
  };

  const onSubscriptionSelected = async (subscription) => {
    try {
      const { topic } = subscription;
      const { namespace } = topic;
      const [ , , , , resourceGroupName] = namespace.id.split("/");
      const rulesIterator = namespace.context().rules.listBySubscriptions(resourceGroupName, namespace.name, topic.name, subscription.name);
      const selectedSubscription = {
        namespaceName: namespace.name,
        namespaceId: namespace.id,
        namespaceUrl: namespace.serviceBusEndpoint,
        subscriptionId: namespace.subscriptionId,
        ...subscription,
        type: "subscription",
        topic,
      };
      onEntitySelected(selectedSubscription);
      const rules = [];
      for await (const rule of rulesIterator) {
        rules.push(rule);
      }
      // update selected entity (topic subscription) with rules, only if subscription is still selected in tree
      // in this case we just patch up rules to any existing info
      if(selectedNodeIdRef.current === selectedSubscription.id) {
        onEntityAdditionalInfoProvided({
          rules,
        });
      }
    } catch (error) {
      setErrorMessage("Error selecting topic subscription or loading filter rules, " + error.toString() );
      throw error;
    }
  }

  const onQueueSelected = (queue) => {
    try {
      const { namespace } = queue;
      onEntitySelected({
        namespaceName: namespace.name,
        namespaceId: namespace.id,
        namespaceUrl: namespace.serviceBusEndpoint,
        subscriptionId: namespace.subscriptionId,
        ...queue,
        type: "queue",
      });
    } catch (error) {
      setErrorMessage("Error selecting queue, " + error.toString() );
    }
  };


  const onEntityTreeItemLabelClick = (event) => {
    event.preventDefault();
  };


  const onTreeNodeSelected = (e, nodeId) => {
    setSelectedNodeId(nodeId);
  }

  return subscriptionsWithNamespaces && Object.keys(subscriptionsWithNamespaces).length > 0 ? (
    <Box className={classes.root}>
      <Grid className={classes.filterContainer} container spacing={1} alignItems="flex-end">
          <Grid item>
            <Box display={{ xs: 'none', md: 'none', lg: 'none', xl: 'block' }}>
              <FilterList  />
            </Box>
          </Grid>
          <Grid item xs={10}>
            <TextField
              onChange={(e) => { setFilter(e.target.value); }} 
              value={filter}
              id="input-with-icon-grid" 
              fullWidth
              InputProps={{endAdornment: <InputAdornment position="end">
                <IconButton className={classes.clearFilterIcon} onClick={() => { setFilter("") }}><Clear fontSize="small" color={filter.length > 0 ? "action" : "disabled"} /></IconButton>
              </InputAdornment>}}
              label="Filter Entities" />
          </Grid>
        </Grid>
      <TreeView
      onNodeSelect={onTreeNodeSelected}
      multiSelect={false}
      defaultCollapseIcon={<ArrowDropDown />}
      defaultExpandIcon={<ArrowRight />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      >
      { Object.values(subscriptionsWithNamespaces).sort(itemSort).map((item) =>  {
          return (<StyledTreeItem labelIcon={AzureIcon} labelText={item.name} nodeId={item.id} key={item.id}>
            {Object.values(item.namespaces).sort(itemSort).map((namespace) => {
              return(
                <StyledTreeItem labelIcon={NamespaceIcon} labelText={namespace.tags.displayName ? namespace.tags.displayName : namespace.name} nodeId={namespace.id}  key={namespace.id} onClick={() => {onNamespaceSelected(namespace)}}>
                  
                  {namespace.isLoading || namespace.topics === undefined || namespace.queues === undefined ? 
                    <StyledTreeItem depth={2}  labelText="Loading..." labelIcon={HourglassFull} nodeId={namespace.id + "-loading"} key={namespace.id + "-loading"} /> : undefined}
                  
                  {namespace.topics && Object.keys(namespace.topics).length > 0 
                    ? <StyledTreeItem labelIcon={Email} labelIconStyle={{ color: blue[800] }} depth={2} labelText="Topics" nodeId={namespace.id + "-topics"} key={namespace.id + "-topics"}>
                          {Object.values(namespace.topics).filter(entityNameFilter).sort(itemSort).map((topic) => 
                          <StyledTreeItem labelIcon={Email} labelIconStyle={{ color: blue[800] }}
                            depth={3}
                             onLabelClick={onEntityTreeItemLabelClick}
                            labelTooltip={`${topic.name.length > maxCharacterLength ? topic.name : ""}`}
                            labelText={`${topic.name.length > maxCharacterLength ? topic.name.substr(0, maxCharacterLength) + ".." : topic.name}`}
                            labelInfo={`${topic.countDetails.activeMessageCount}, ${topic.countDetails.deadLetterMessageCount}`} 
                            key={topic.id}
                            nodeId={topic.id}
                            onClick={() => {onTopicSelected(topic)}} >
                              {topic.subscriptions === undefined ? 
                                <StyledTreeItem  labelText="Loading..." labelIcon={HourglassFull} depth={4} nodeId={topic.id + "loading"} key={topic.id + "loading"} /> : undefined}
                                {topic.subscriptions 
                                ? topic.subscriptions.sort(itemSort).map((subscription) => 
                                <StyledTreeItem labelIcon={Email} labelIconStyle={{ color: purple[300] }}  
                                depth={4}
                                 onLabelClick={onEntityTreeItemLabelClick}
                                  labelTooltip={`${subscription.name.length > 28 ? subscription.name : ""}`}
                                  labelText={`${subscription.name.length > 28 ? subscription.name.substr(0, 28) + ".." : subscription.name}`}
                                  labelInfo={`${subscription.countDetails.activeMessageCount}, ${subscription.countDetails.deadLetterMessageCount}`} 
                                  onClick={() => {onSubscriptionSelected(subscription)}}
                                  nodeId={subscription.id} 
                                  key={subscription.id} 
                                  />)
                                : undefined
                              }
                            </StyledTreeItem>)}
                    </StyledTreeItem>
                    : undefined
                  }

                  {namespace.queues && Object.keys(namespace.queues).length > 0 
                    ? <StyledTreeItem labelIcon={Email} labelIconStyle={{ color: deepPurple[800] }} depth={2}  labelText="Queues" nodeId={namespace.id + "-queues"} key={namespace.id + "-queues"}>
                          {Object.values(namespace.queues).filter(entityNameFilter).sort(itemSort).map((queue) => 
                          <StyledTreeItem labelIcon={Email} labelIconStyle={{ color: deepPurple[800] }} 
                          depth={3} 
                           onLabelClick={onEntityTreeItemLabelClick}
                          labelIconColor="primary" 
                          labelTooltip={`${queue.name.length > maxCharacterLength ? queue.name : ""}`}
                          labelText={`${queue.name.length > maxCharacterLength ? queue.name.substr(0, maxCharacterLength) + ".." : queue.name}`}
                          labelInfo={`${queue.countDetails.activeMessageCount}, ${queue.countDetails.deadLetterMessageCount}`} 
                          nodeId={queue.id}
                          key={queue.id}
                          onClick={() => {onQueueSelected(queue)}} />)}
                    </StyledTreeItem>
                    : undefined
                  }

                </StyledTreeItem>
              )
            })}
          </StyledTreeItem>)
      })}
    </TreeView>
    </Box>
  )
  : <>
    <CircularProgress className={classes.spinner} color="secondary" />
    <Alert className={classes.alert} severity="blank">
            Make sure you have access to Azure Service Bus namespaces (IAM). <a rel='noreferrer' href='https://github.com/cloudbricksio/ServiceBusCloudExplorerSupport/wiki/User-guide#authenticate-via-user-identity' target='_blank'>Read more</a>
    </Alert>
    </>;
}