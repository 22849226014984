import { SvgIcon } from '@material-ui/core';
import React from 'react';


export const AzureIcon = (props) => {
    return (
      <SvgIcon viewBox="0 0 64 64" {...props}>
        <path d="M14.62 55H64L37.4 8.91l-8.1 22.25 15.56 18.5L14.62 55zM35.28 4.84l-19 15.95L0 48.98h14.68l20.6-44.14z" fill="#0072c6"/>
      </SvgIcon>
    );
}

export default AzureIcon;