import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    syntaxHighlighter: {
        overflowX: "hidden!important",
        height: '330px',
        wordBreak: 'break-word'
    }
}));

export default function SelectedMessage ({selectedMessage}) {
    const message = selectedMessage 
        && selectedMessage.body 
        && Buffer.isBuffer(selectedMessage.body) ? {
            ...selectedMessage,
            body: selectedMessage.body.toString('utf8')
        } : selectedMessage;
    const classes = useStyles();
    return <div className={classes.root}>
        <Grid container spacing={0}>
            <Grid item xs={11}>
                <Typography variant="h6">
                    Selected Message
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <SyntaxHighlighter
                    className={classes.syntaxHighlighter}
                    wrapLongLines={true}
                    wrapLines={true}
                    language="json" style={atomOneDark}>
                        {message ? JSON.stringify(message, null, 4) : "No Content"}
                </SyntaxHighlighter>
            </Grid>
        </Grid>
    </div>
}