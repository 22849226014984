import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { SendMessage } from './SendMessage';
import { ReceiveMessages } from './ReceiveMessages';
import { ReplayMessages } from './ReplayMessages';
import { BrowseMessages } from './BrowseMessages';
import { Delete, Visibility, Send, Replay, List } from '@material-ui/icons';
import { useMediaQuery } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabPanelBox: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  tabPanelContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  hiddenTapBox: {
    position: "absolute",
    left: "-999em",
    visibility: "hidden"
  }
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" className={value !== index ? classes.hiddenTapBox : null} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other} >
      {(
        <div className={classes.tabPanelContainer}>
          <Box className={classes.tabPanelBox}>
            {children}
          </Box>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


export default function EntityMessageManager(props) {
  const { setErrorMessage, isLicenced } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(props.entityInfo.entityType === "topic" ? "send" : "browse");
  const isNotLargeScreen = useMediaQuery(theme => theme.breakpoints.down("lg"));

  useEffect(() => {
    setValue(props.entityInfo.entityType === "topic" ? "send" : "browse");
  }
  , [ props.entityInfo.entityType ]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs scrollButtons='on' value={value} onChange={handleChange} indicatorColor="primary" textColor="primary"  variant={ isNotLargeScreen? "scrollable" : "fullWidth" } aria-label="Messages Toolbar">
          <Tab style={Object.assign({}, props.entityInfo.entityType !== "topic"? {} : { display: 'none' })} label="Browse" icon={<List />} {...tabProps(0)} value="browse" />
          <Tab style={Object.assign({}, props.entityInfo.entityType !== "subscription"? {} : { display: 'none' })} label="Send" icon={<Send />} {...tabProps(1)} value="send" />
          <Tab style={Object.assign({}, props.entityInfo.entityType !== "topic"? {} : { display: 'none' })} label="Peek" icon={<Visibility />} {...tabProps(2)} value="peek" />
          <Tab style={Object.assign({}, props.entityInfo.entityType !== "topic"? {} : { display: 'none' })} label="Receive" icon={<Delete />} {...tabProps(3)} value="receive" />
          <Tab style={Object.assign({}, props.entityInfo.entityType !== "topic"? {} : { display: 'none' })} label="Replay" icon={<Replay />} {...tabProps(4)} value="replay" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index="send" dir={theme.direction}>
        <SendMessage 
                    entityInfo={props.entityInfo} 
                    credentials={props.credentials} 
                    onMessageEventReceived={props.onMessageEventReceived} 
                    selectedEntity={props.selectedEntity} 
                    setErrorMessage={setErrorMessage}
                    isLicenced={isLicenced}
            />
      </TabPanel> 
      <TabPanel value={value} index="browse" dir={theme.direction}>
           <BrowseMessages 
                  entityInfo={props.entityInfo} 
                  credentials={props.credentials} 
                  selectedEntity={props.selectedEntity} 
                  setErrorMessage={setErrorMessage}
                  isLicenced={isLicenced}
                  onMessageSelected={props.onMessageSelected}
                  isDisplayed={value === "browse"}
                  onMessageEventReceived={props.onMessageEventReceived}
            />
      </TabPanel> 
      <TabPanel value={value} index="peek" dir={theme.direction}>
        <ReceiveMessages 
                    receiveMode="peekLock"
                    entityInfo={props.entityInfo} 
                    credentials={props.credentials} 
                    onMessageEventReceived={props.onMessageEventReceived} 
                    selectedEntity={props.selectedEntity} 
                    setErrorMessage={setErrorMessage}
                    />
      </TabPanel>
      <TabPanel value={value} index="receive" dir={theme.direction}>
        <ReceiveMessages 
                    receiveMode="receiveAndDelete"
                    entityInfo={props.entityInfo} 
                    credentials={props.credentials} 
                    onMessageEventReceived={props.onMessageEventReceived} 
                    selectedEntity={props.selectedEntity} 
                    setErrorMessage={setErrorMessage}
                    />
      </TabPanel>
      <TabPanel value={value} index="replay" dir={theme.direction}>
        <ReplayMessages 
                    entityInfo={props.entityInfo} 
                    credentials={props.credentials} 
                    onMessageEventReceived={props.onMessageEventReceived} 
                    selectedEntity={props.selectedEntity} 
                    setErrorMessage={setErrorMessage}
        />
      </TabPanel>
    </div>
  );
}