import { Paper, Grid, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../components/Footer';
import React, { useState, useEffect } from 'react';
import { resolveSubscription } from '../modules/subscriptionManagement/resolveSubscription';
import { activateSubscription } from '../modules/subscriptionManagement/activateSubscription';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { useMsal } from "@azure/msal-react";
import { sleep } from '../modules/sleep';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),

    },
    container: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(5),
    },
    loginContainer: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    title: {
        marginBottom:theme.spacing(3),
    }
}));

export default function AccountPage({setErrorMessage, loggedInAccount}) {
    const [purchasedSubscription, setPurchasedSubscription] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMessage, setIsLoadingMessage] = useState();
    const { instance } = useMsal();
    const { browserCredentials } = instance;
  
    const activate = async () => {
        const params = new URLSearchParams(window.location.search);
        setIsLoading(true);
        setIsLoadingMessage("Activating your subscription. This may take few minutes. Please don't close this window.");
        try {
            let activatedSubscription = await activateSubscription(purchasedSubscription.id, browserCredentials);
            window.appInsights.trackEvent({
                name: "Subscription Activated",
                properties: activatedSubscription
            });
            //keep loading subscription till we confirm its Subscribed as microsoft is doing an async process
            if(activatedSubscription.saasSubscriptionStatus !== "Subscribed"){
                do {
                    await sleep(4000);
                    const { data: subscription } = await resolveSubscription(params.get("token"), loggedInAccount.accessToken);
                    activatedSubscription = subscription.subscription;
                }  while (activatedSubscription.saasSubscriptionStatus !== "Subscribed");
            }
            setPurchasedSubscription(activatedSubscription);
            setIsLoading(false);
        } catch (error) {
            window.appInsights.trackException({
                exception: error,
                properties: {
                    process: "activating subscription"
                }
            });
            setErrorMessage(`There was an error during activating subscription: ${error.message}.`);
            setIsLoading(false);
            throw(error);
        }
    };

    
    useEffect(() => {
        const loadSubscription = async () => {
            const params = new URLSearchParams(window.location.search);
            if (loggedInAccount && params.has("token")) {
                //need to resolve the subscription
                setIsLoading(true);
                setIsLoadingMessage("Loading subscription details, please wait");
                try {
                    const token = loggedInAccount.accessToken;
                    const { data: subscription } = await resolveSubscription(params.get("token"), token);
                    window.appInsights.trackEvent({
                        name: "Subscription Resolved",
                        properties: subscription
                    });
                    setPurchasedSubscription(subscription.subscription);
                    setIsLoading(false);
                    setIsLoadingMessage("")
                } catch (error) {
                    window.appInsights.trackException({
                        exception: error,
                        properties: {
                            process: "resolving subscription"
                        }
                    });
                    setErrorMessage(`There was an error during resolving subscription: ${error.message}.`);
                    setIsLoading(false);
                    setIsLoadingMessage("");
                    throw (error);
                }
            }
        }
        loadSubscription();
    }, [loggedInAccount, setErrorMessage ]);


    const getStatusActions = () => {
        switch (purchasedSubscription.saasSubscriptionStatus) {
            case "NotStarted":
            case "PendingFulfillmentStart":
                return  <Button variant="contained" color="primary" disabled={isLoading} onClick={activate}>
                            Confirm and activate subscription
                        </Button>
            case "Subscribed":
                return <Alert severity="success">Your subscription is active, to cancel or modify please visit SaaS resource in <a href='https://portal.azure.com/#blade/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.SaaS%2Fresources'>Azure Portal</a> or <a href='/'>Go to application!</a></Alert>
            case "Suspended":
                return <Alert severity="warning">Your subscription is suspended, to resume subscription please fix billing in SaaS resource page in <a href='https://portal.azure.com/#blade/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.SaaS%2Fresources'>Azure Portal</a></Alert>
            case "Unsubscribed":
                return <Alert severity="error">Selected subscription is not active, to resubscribe please create a new SaaS resource in <a href='https://portal.azure.com/#blade/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.SaaS%2Fresources'>Azure Portal</a></Alert>
            default:
                return <Alert severity="error">{`Error: Unexpected subscription status: ${purchasedSubscription.saasSubscriptionStatus}`}</Alert>;
        }
    };

    const classes = useStyles();
    return <><div className={classes.root}>
        {loggedInAccount ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper className={classes.container}>
                            <Typography variant="h5" gutterBottom className={classes.title}>
                                Subscription details
                            </Typography>
                            <Grid container spacing={4}>
                                {isLoading ? 
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <LinearProgress />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>{isLoadingMessage}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                : <></>}
                                { purchasedSubscription ? 
                                <>
                                    <Grid item xs={1}>
                                        <Typography>Name</Typography>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography>{purchasedSubscription.name}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography>Status</Typography>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography>{purchasedSubscription.saasSubscriptionStatus}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography>Tenant Id</Typography>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography>{purchasedSubscription.beneficiary.tenantId}</Typography>
                                    </Grid>
                                    {purchasedSubscription.term.startDate ? <>
                                        <Grid item xs={1}>
                                            <Typography>Term</Typography>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography>{`(Monthly) Start: ${purchasedSubscription.term.startDate} - End: ${purchasedSubscription.term.endDate}`}</Typography>
                                        </Grid>
                                    </> : <></>}
                                    <Grid item xs={1}>
                                        <Typography>Auto Renew</Typography>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography>{purchasedSubscription.autoRenew ? "On": "Off"}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        { getStatusActions() }
                                    </Grid>
                                </> 
                                : <></> 
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            :
            <></>
        }
    </div>
    <Footer />
    </>;
}